export const MODAL_MODES = {
  NO_SELECTED: 'NO_SELECTED',
  NEW: 'NEW',
  RENAME: 'RENAME',
  CLEAR: 'CLEAR',
  DELETE: 'DELETE',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
  SETTINGS: 'SETTINGS',
};
export const FILTER_MODE = 'filter-mode';
export const LIST_MODE = 'list-mode';
