import ListAlerts from 'components/ListAlerts';
import Table from 'components/Table';
import { PAGE_GROWTH } from 'constants';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import { Navigate } from 'react-router';

const Sellers = () => {
  const { isSellersAvailable } = useUserTariffs();

  const { isOzonPlatform } = useFilterReducer();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.SELLERS_GROWTH,
  });

  if (!isSellersAvailable) {
    return <Navigate to={PAGE_GROWTH} />;
  }

  const { dates } = data?.pages[0]?.data || {};
  return (
    <>
      <Table
        data={data}
        columnsData={dates}
        dataPagesKey={'data'}
        type={TABLE_TYPE.SELLERS_GROWTH}
        hideSoldColumn={isOzonPlatform}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Sellers;
