import { SEO_CLUSTERS_BY_GROUP, TABLE_TYPE } from '../../../constants';
import { ClustersByArticleInput } from './ClustersByArticleInput';
import { ClustersByGroupInput } from './ClustersByGroupInput';

export const MODES = {
  INSERT: 'insert',
  RESULT: 'result',
};

const PARAM_KEY = 'search';

const setSearchParams = (value, navigate, setMode) => {
  const params = new URLSearchParams({
    [PARAM_KEY]: value,
  });
  navigate(
    {
      pathname: location.pathname, // Keep the current pathname
      search: params.toString(), // Add the search params
    },
    { replace: true },
  );
  setMode(MODES.RESULT);
};

const getClusterInfoByType = (type, searchParams) => {
  switch (type) {
    // case SEO_CLUSTERS_BY_ARTICLE: {
    default: {
      const search = searchParams?.get('search');
      return {
        title: 'Кластеры по артикулу "' + search + '"',
        subTitle:
          'Инструмент позволяет точно определить приоритетную категорию товаров и предоставить информацию, соответствующую запросам в этом кластере.',
        inputComponent: ClustersByArticleInput,
      };
    }
    case SEO_CLUSTERS_BY_GROUP: {
      const search = searchParams?.getAll('search[]') || [];
      return {
        title: 'Кластеры по запросам "' + (Array.isArray(search) ? search.join(', ') : '') + '"',
        subTitle:
          'Инструмент позволяет точно определить приоритетную категорию товаров и предоставить информацию, соответствующую запросам в этом кластере.',
        inputComponent: ClustersByGroupInput,
      };
    }
    // default: {
    //   return {
    //     subTitle:
    //       'Инструмент позволяет точно определить приоритетную категорию товаров и предоставить информацию, соответствующую запросам в этом кластере.',
    //     inputComponent: ClustersByRequestInput,
    //   };
    // }
  }
};

const getClusterResultsByType = (type, searchParams) => {
  switch (type) {
    // case SEO_CLUSTERS_BY_ARTICLE: {
    default: {
      const search = searchParams.get('search');
      return {
        tableType: TABLE_TYPE.SEO_CLUSTERS_ARTICLE_RESULTS,
        seoQueryProps: {
          filter: {
            platform: ['2'],
            product: [search],
          },
        },

        prepareData: (data) => ({ data: data?.table1?.data, pagination: true }),
      };
    }
    case SEO_CLUSTERS_BY_GROUP: {
      const search = searchParams.getAll('search[]');
      return {
        tableType: TABLE_TYPE.SEO_CLUSTERS_PHRASE_RESULTS,
        seoQueryProps: {
          filter: {
            platform: ['2'],
          },
          restricts: {
            search,
          },
          sort: 'frequency_wb',
          order: 'desc',
        },
        prepareData: (data) => {
          return data;
        },
      };
    }
    // default: {
    //   return {
    //     subTitle:
    //       'Инструмент позволяет точно определить приоритетную категорию товаров и предоставить информацию, соответствующую запросам в этом кластере.',
    //     inputComponent: ClustersByRequestInput,
    //   };
    // }
  }
};

export { getClusterInfoByType, getClusterResultsByType, setSearchParams };
