import cn from 'classnames';
import PropTypes from 'prop-types';

import ButtonPlusMinus from 'components/ButtonPlusMinus';
import ButtonStar from 'components/ButtonStar';
import { TableCellFilterLinkButton } from 'components/TableCellFilterLink';
import { LIST_TYPE } from 'constants/list';
import { FIELD_IDS } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { HIERARCHY_KEY } from 'helpers/generateGridRowData';
import { useCategoryChildren } from 'hooks/useCategoryChildren';
import { useFavourite } from 'hooks/useFavourite';
import { useCallback, useEffect, useRef } from 'react';

const WB_MAIN_CATEGORY = 6095;
const OZON_MAIN_CATEGORY = 1;

const highlightedText = ({ text, filterModelTree, filterText }) => {
  if (
    (!filterText && !filterModelTree) ||
    !!(
      filterModelTree &&
      (!filterModelTree.value ||
        filterModelTree.filterType !== 'text' ||
        filterModelTree.type !== 'contains')
    )
  ) {
    return text;
  }

  const value = filterText || filterModelTree.value;
  const index = text.toLowerCase().indexOf(value.toLowerCase());

  if (index === -1) {
    return text;
  }

  const beforeStr = text.substr(0, index);
  const afterStr = text.substr(index + value.length);

  return (
    <span>
      {beforeStr}
      <span
        className={cn('text-green', {
          ['capitalize']: beforeStr.length === 0,
        })}
      >
        {value}
      </span>
      {afterStr}
    </span>
  );
};

const overwriteHierarchyValue = {
  wb: 'Wildberries',
};

const TableCellCategoriesHighlighted = (props) => {
  let { value, api, data, colDef, node, columnApi, column } = props;
  const { isFilterRangeApplied, isFilterApplied, filterText } = useFilterReducer();
  const categoryId = data.id;
  const ref = useRef();

  const {
    listData,
    selected,
    handleFavourite,
    isLoading: isFavouritesLoading,
  } = useFavourite(LIST_TYPE.BY_CATEGORIES, categoryId);

  const { id: cellId, field } = colDef;
  const type = cellId || field;

  const key = data?.[FIELD_IDS[type]] || data?.id;

  const hierarchy = data?.[HIERARCHY_KEY];
  const isMainCategory = key === WB_MAIN_CATEGORY || key === OZON_MAIN_CATEGORY;

  const { handleCategoryChildren, isExpanded, isLoading } = useCategoryChildren({
    category: !isMainCategory ? key : null,
    columnDefs: api.columnModel.columnDefs,
    hierarchy,
    node,
  });
  // как только применён фильтр по бренду/категории продавцу и от-до,
  // фильтр от-до начинает работать со стороны aggrid,
  // поэтому детей надо считать самостоятельно
  // до того, как фильтр применён, детей ндо брать в ответе бэка
  const countChildren =
    isFilterRangeApplied && isFilterApplied ? node.allChildrenCount : data?.children_count || 0;

  const hasChildren = countChildren > 0;
  value = overwriteHierarchyValue[value] || value;

  const filterModel = api.getFilterModel();

  const filterModelTree = filterModel?.['ag-Grid-AutoColumn'] || null;
  const result = highlightedText({ text: value, filterModelTree, filterText: filterText?.[field] });

  const getOffset = useCallback(() => {
    let offset = (hierarchy?.length - 1) * 28;
    if (!hasChildren) {
      offset += 24;
    }
    return offset;
  }, [hierarchy, hasChildren]);

  useEffect(() => {
    const resultWidth = ref.current.offsetWidth + getOffset() + 15;
    if (column.actualWidth < resultWidth) {
      columnApi.setColumnWidth(column, resultWidth);
    }
  });

  return (
    <span
      className="w-fit flex items-center overflow-visible"
      style={{ marginLeft: `${getOffset()}px` }}
      ref={ref}
    >
      {!!hasChildren && (
        <ButtonPlusMinus
          onClick={handleCategoryChildren}
          isLoading={isLoading}
          isExpanded={isExpanded}
        />
      )}

      <div className="-ml-1">
        <ButtonStar
          active={!!selected.length}
          options={listData}
          isLoading={isFavouritesLoading}
          onChange={handleFavourite}
          selected={selected}
          type={LIST_TYPE.BY_CATEGORIES}
        />
      </div>

      {!isMainCategory ? (
        <TableCellFilterLinkButton id={key} type={type} value={result} />
      ) : (
        <span>{result}</span>
      )}

      {!!countChildren && (
        <span className="ml-1 text-gray-400">({countChildren.toLocaleString('ru')})</span>
      )}
    </span>
  );
};

TableCellCategoriesHighlighted.propTypes = {
  value: PropTypes.string.isRequired,
  api: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  data: PropTypes.object,
  colDef: PropTypes.shape({
    field: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.number,
    minWidth: PropTypes.number,
  }),
  columnApi: PropTypes.any,
  column: PropTypes.any,
  eGridCell: PropTypes.any,
};
export default TableCellCategoriesHighlighted;
