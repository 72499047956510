import { useParams } from 'react-router';

import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';

const Categories = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_PROMOTIONS,
    id,
    enabled: true,
  });

  return (
    <>
      <Table
        data={data}
        type={TABLE_TYPE.PRODUCT_PROMOTIONS}
        isLoading={isLoading || isFetching}
        noRowsText={`Товар не участвовал в акциях в указанный период`}
      />
    </>
  );
};

export default Categories;
