import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ButtonStar from 'components/ButtonStar';
import { LIST_TYPE } from 'constants/list';
import { useFavourite } from 'hooks/useFavourite';
import { useProductLink } from 'hooks/useLocationPath';

const TableCellProductLink = (props) => {
  const { value = '', valueFormatted = '', data = {}, withoutFavorite, idKey } = props;
  const productId = idKey ? data[idKey] : data.id || data.product_id;
  const link = useProductLink(productId);
  const { listData, selected, handleFavourite, isLoading } = useFavourite(
    LIST_TYPE.BY_PRODUCTS,
    productId,
  );

  let cellValue = valueFormatted ? valueFormatted : value;

  if (!productId || cellValue === '-') return idKey ? '' : '-';

  return (
    <span className="flex -ml-1">
      {!withoutFavorite && (
        <ButtonStar
          active={!!selected.length}
          options={listData}
          onChange={handleFavourite}
          isLoading={isLoading}
          selected={selected}
          type={LIST_TYPE.BY_PRODUCTS}
        />
      )}
      <Link to={link} target="_blank" className="truncate block">
        {typeof cellValue === 'object' ? (
          <span>{cellValue}</span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: cellValue }} />
        )}
      </Link>
    </span>
  );
};

TableCellProductLink.propTypes = {
  data: PropTypes.object,
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  withoutFavorite: PropTypes.bool,
  idKey: PropTypes.string,
};

export default TableCellProductLink;
