import { PAGE_GROWTH, PAGE_SEASONALITY } from 'constants';
import { useLocation } from 'react-router';

export const PanelTitle = () => {
  const location = useLocation();

  const getTitle = () => {
    if (location.pathname.startsWith(PAGE_GROWTH)) return 'Темпы роста';
    if (location.pathname.startsWith(PAGE_SEASONALITY)) return 'Сезонность';
    return 'Аналитика ассортимента';
  };

  return (
    <nav>
      <h1>{getTitle()}</h1>
    </nav>
  );
};
