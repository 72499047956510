import PropTypes from 'prop-types';

import Layout from 'components/Layout/Layout';
import TableLayout from 'components/Layout/TableLayout';

const TableSeasonLayout = ({ children }) => {
  return (
    <TableLayout isPeriodControlsShown={true} isDateControlsShown={false}>
      <Layout>{children}</Layout>
    </TableLayout>
  );
};

TableSeasonLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default TableSeasonLayout;
