import classNames from 'classnames';
import PropTypes from 'prop-types';

const TableListingWithPositionCell = (props) => {
  const { value, data, mainField } = props || {};

  //  'bg-orange-100': (params) => params.value?.promotion_type?.id === '1',
  //  'bg-pink-100': (params) => params.value?.promotion_type?.id === '2',
  let label = '';
  const { listing, sort_position, new_position, old_position, cpm, promotion_type } = value || {};
  let hasPromotion = false;
  if (!value) {
    label = '-';
  } else if (value === ' ') {
    label = '';
  } else if (typeof value === 'number') {
    label = value;
  } else {
    hasPromotion = promotion_type?.id !== null;

    label = listing || sort_position;
    if (new_position === null) {
      label = listing || '-';
    } else if (old_position === null) {
      label = sort_position || '-';
    }
  }

  const diff = data[mainField?.split('_').join('_diff_')] || null;

  return (
    <div
      className="flex justify-start items-center h-auto my-2 pl-2"
      // className={classNames('flex justify-center items-center h-auto my-2 pl-2', {
      //   'border-l-2 border-[#E2A339]': hasPromotion && promotion_type?.id == 1,
      //   'border-l-2 border-[#ED5B73]': hasPromotion && promotion_type?.id == 2,
      //   // 'border-l-2 border-[#4D73FB]': 1 == 1 || (hasPromotion && promotion_type?.id === 2),
      // })}
    >
      <div className={classNames('pr-1 font-bold text-right text-sm')} style={{ width: 30 }}>
        {label}
      </div>

      <div
        className={classNames('mr-1 text-xs', {
          'text-[#5D9D52]': diff > 0,
          'text-[#D4483E]': diff < 0,
          '!text-gray-300': diff === 0,
        })}
      >
        {!diff ? '' : diff < 0 ? `-${Math.abs(diff)}` : `+${Math.abs(diff)}`}
      </div>

      {/* <div className="text-gray-400 text-xs text-right" style={{ width: 40 }}>
        {hasPromotion && (
          <>
            {old_position} <span className="font-light">→</span>
          </>
        )}
      </div> */}

      <div className="text-gray-400 text-xs text-right text-gray-300">
        {hasPromotion && <>({cpm} ₽)</>}
      </div>
    </div>
  );
};

TableListingWithPositionCell.propTypes = {
  data: PropTypes.any,
  mainField: PropTypes.any,
  isSearch: PropTypes.bool,
};

export default TableListingWithPositionCell;

// const TableListingWithPositionCell2222222 = (props) => {
//   const { value, isSearch = false, data, mainField } = props || {};

//   if (!value) {
//     return '-';
//   }
//   if (value === ' ') {
//     return '';
//   }
//   if (typeof value === 'number') {
//     return value;
//   }

//   const { listing, sort_position, new_position, old_position, cpm, promotion_type } = value || {};

//   const hasPromotion = promotion_type?.id !== null;

//   if (new_position === null) {
//     return listing || '-';
//   }
//   if (old_position === null) {
//     return sort_position || '-';
//   }

//   const diff = isSearch ? data[mainField.split('_').join('_diff_')] || null : null;
//   return (
//     <div style={{ height: 100 }}>
//       <div className="flex justify-end ">
//         {isSearch && (
//           <div
//             className={cn('mr-2', {
//               'text-[#5D9D52]': diff > 0,
//               'text-[#D4483E]': diff < 0,
//               '!text-gray-300': diff === 0,
//             })}
//           >
//             {!diff ? '' : diff < 0 ? `↓ ${Math.abs(diff)}` : `↑ ${Math.abs(diff)}`}
//           </div>
//         )}

//         <div>{listing || sort_position}</div>
//       </div>
//       {hasPromotion && (
//         <div className="text-gray-400 text-xs leading-5 -mt-2">
//           <div>
//             {old_position} ›› {new_position}
//           </div>
//           {cpm} ₽
//         </div>
//       )}
//     </div>
//   );
// };
