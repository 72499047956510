const API_URL = process.env.REACT_APP_API_URL;
const API_TEST_URL = process.env.REACT_APP_API_TEST_URL;
const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const API_AUTH_TEST_URL = process.env.REACT_APP_AUTH_TEST_URL;

const LOCAL_STORAGE_URL_KEY = 'wc_burl';

export const isFrontEnviroment = process.env.REACT_APP_ENV === 'front';

export const getApi = () => {
  const localStorageUrl = localStorage.getItem(LOCAL_STORAGE_URL_KEY);
  if (localStorageUrl && isFrontEnviroment) {
    return localStorageUrl;
  }
  return window.location.hostname === 'test.wecheck.fun' ? API_TEST_URL : API_URL;
};

export const getAuthApi = () => {
  const localStorageUrl = localStorage.getItem(LOCAL_STORAGE_URL_KEY);
  if (localStorageUrl && isFrontEnviroment) {
    return localStorageUrl + '/auth';
  }
  return window.location.hostname === 'test.wecheck.fun' ? API_AUTH_TEST_URL : API_AUTH_URL;
};
