import { IS_PRODUCTION } from 'constants/environment';
import gtag from 'ga-gtag';
import ym from 'react-yandex-metrika';
import { isFrontEnviroment } from './api';

export const sendToGA = (type, params) => {
  if (!IS_PRODUCTION || isFrontEnviroment) {
    return;
  }
  gtag('event', type, params);
};

export const sendToYM = (type) => {
  if (!IS_PRODUCTION || isFrontEnviroment) {
    return;
  }
  ym('reachGoal', type);
};
