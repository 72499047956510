// import moment from 'moment';

// import { DATE_FORMAT } from 'context/filter/filter.context.reducer.methods';
import CardList from 'components/CardList';
import TableCardMonopolization from 'components/TableCardMonopolization';
import { CHART_TYPE } from 'constants/chart';
import { COLUMNS /*, TABLE_TYPE*/ } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { transformDate } from 'helpers/utils';
import { useFetchFilteredChartsData } from 'hooks/useFetchFilteredChartsData';
// import { useFetchFilteredData } from 'hooks/useFetchFilteredData';
// import { isTrendsPage } from 'utils/pages';

const VALUE_KEY = 'orders';
const NAME_KEY = 'name';

// const CHART_DATE = [
//   moment().subtract(7, 'days').format(DATE_FORMAT),
//   moment().subtract(1, 'days').format(DATE_FORMAT),
// ];

const getChartData = ({ data, dataKey }) => {
  if (!data.chart_dates) return [];

  const { chart_dates, ...other } = data;
  return chart_dates.map((date, i) => {
    const value = other?.[dataKey]?.[i];
    const splittedDate = date.split('-');
    return {
      [NAME_KEY]: `${splittedDate[2]}.${splittedDate[1]}`,
      [VALUE_KEY]: value === '-' ? null : +value,
    };
  });
};

const TableCardList = () => {
  const { filter } = useFilterReducer();
  const {
    data: chartData,
    isLoading,
    isFetching,
  } = useFetchFilteredChartsData({
    type: CHART_TYPE.ORDERS,
  });

  const {
    data: chartGraphData,
    isLoading: isLoadingGraph,
    isFetching: isFetchingGraph,
  } = useFetchFilteredChartsData({
    type: CHART_TYPE.ORDERS_GRAPH,
  });
  return (
    <CardList
      data={chartData}
      isLoading={
        isLoading ||
        isLoadingGraph ||
        isFetchingGraph ||
        /*categoriesDataLoading ||*/ isFetching /*|| categoriesDataFetching*/
      }
      columns={[
        // column first
        [
          {
            title: 'Заказы',
            labels: ['Руб', 'Шт'],
            shortcut: true,
            isQuestion: true,
            questionText: 'Сумма заказов всех товаров за период по фильтрам',
            tabs: [
              {
                chart: {
                  data: getChartData({ data: chartGraphData || [], dataKey: COLUMNS.ORDERS_RUB }),
                  valueKey: VALUE_KEY,
                  nameKey: NAME_KEY,
                },
                info: [
                  {
                    label: 'За&nbsp;30&nbsp;дней',
                    key: 'orders_rub_30d',
                    type: 'currency',
                    shortcut: true,
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: 'orders_rub_7d',
                    type: 'currency',
                    shortcut: true,
                  },
                  {
                    ...(filter?.date && {
                      label: transformDate(filter.date),
                      key: 'orders_rub',
                      highlighted: true,
                      type: 'currency',
                      shortcut: true,
                    }),
                  },
                ],
              },
              {
                chart: {
                  data: getChartData({ data: chartGraphData || [], dataKey: COLUMNS.ORDERS_QTY }),
                  valueKey: VALUE_KEY,
                  nameKey: NAME_KEY,
                },
                info: [
                  {
                    label: 'За&nbsp;30&nbsp;дней',
                    key: 'orders_qty_30d',
                    type: 'amount',
                    shortcut: true,
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: 'orders_qty_7d',
                    type: 'amount',
                    shortcut: true,
                  },
                  {
                    ...(filter?.date && {
                      label: transformDate(filter.date),
                      key: 'orders_qty',
                      highlighted: true,
                      shortcut: true,
                      type: 'amount',
                    }),
                  },
                ],
              },
            ],
          },
        ],
        // column second
        [
          {
            labels: ['Товары', 'Продавцы', 'Бренды'],
            isQuestion: true,
            isQuestionToRight: true,
            tabs: [
              {
                coloredBack: [
                  {
                    label: 'В&nbsp;наличии',
                    key: 'sku_qty',
                    type: 'amount',
                    tooltip: `
                  Количество уникальных артикулов, которые были<br />в наличии хотя бы один день за
                  период`,
                  },
                  {
                    label: 'С&nbsp;заказами',
                    key: 'sku_with_orders_qty',
                    type: 'amount',
                    color: 'green',
                    tooltip: `
                  Количество уникальных артикулов с хотя бы<br />одним заказом за период`,
                  },
                  {
                    label:
                      '<span>Доля товаров, у которых был хотя бы один заказ за выбранный период</span>',
                    key: 'sku_with_orders_percent',
                    pie: true,
                    percent: true,
                  },
                ],
              },
              {
                coloredBack: [
                  {
                    label: 'В&nbsp;наличии',
                    key: 'sellers_qty',
                    type: 'amount',
                    tooltip: `
                  Продавцы, у которых был в наличии<br />хотя бы один товар за период`,
                  },
                  {
                    label: 'С&nbsp;заказами',
                    key: 'sellers_with_orders_qty',
                    type: 'amount',
                    color: 'green',
                    tooltip: `
                  Продавцы с хотя бы одним заказом за период`,
                  },
                  {
                    label:
                      '<span>Доля продавцов, у которых был хотя бы один заказ за выбранный период</span>',
                    key: 'sellers_with_orders_percent',
                    pie: true,
                    percent: true,
                  },
                ],
              },
              {
                coloredBack: [
                  {
                    label: 'В&nbsp;наличии',
                    key: 'brands_qty',
                    type: 'amount',
                    tooltip: `
                  Бренды, у которых был в наличии<br />хотя бы один товар за период`,
                  },
                  {
                    label: 'С&nbsp;заказами',
                    key: 'brands_with_orders_qty',
                    type: 'amount',
                    color: 'green',
                    tooltip: `
                  Бренды с хотя бы одним заказом за период`,
                  },
                  {
                    label:
                      '<span>Доля брендов, у которых был хотя бы один заказ за выбранный период</span>',
                    key: 'brands_with_orders_percent',
                    pie: true,
                    percent: true,
                  },
                ],
              },
            ],
          },
        ],
        // column third
        [
          {
            title: 'Среднее',
            labels: ['Руб', 'Шт'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Заказов&nbsp;в&nbsp;сутки',
                    key: 'avg_orders_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Сумма всех заказов / кол-во дней в периоде.',
                  },
                  {
                    label: 'У&nbsp;одного&nbsp;продавца',
                    key: 'avg_orders_per_seller_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Сумма всех заказов / кол-во продавцов с заказами.',
                  },
                  {
                    label: 'Одного&nbsp;товара',
                    key: 'avg_orders_per_sku_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Сумма всех заказов / кол-во товаров с заказами',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Заказов&nbsp;в&nbsp;сутки',
                    key: 'avg_orders_qty',
                    type: 'amount',
                  },
                  {
                    label: 'У&nbsp;одного&nbsp;продавца',
                    key: 'avg_orders_per_seller_qty',
                    type: 'amount',
                  },
                  {
                    label: 'Одного&nbsp;товара',
                    key: 'avg_orders_per_sku_qty',
                    type: 'amount',
                  },
                ],
              },
            ],
          },
          {
            tabs: [
              {
                rating: {
                  ratingKey: 'rating',
                  reviewKey: COLUMNS.REVIEWS_QTY,
                },
              },
            ],
          },
        ],
        // column fourth
        [
          {
            title: 'Средняя цена',
            labels: ['Руб'],
            tabs: [
              {
                info: [
                  {
                    label: 'Средняя цена товара',
                    key: 'avg_sku_price',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Среднее арифметическое цен товаров, которые были в наличии',
                  },
                  {
                    label: 'Средняя цена заказанного товара',
                    key: 'avg_sku_with_order_price',
                    type: 'currency',
                    isQuestion: true,
                    questionText:
                      'Среднее арифметическое цен товаров, у которых был хотя бы один заказ за период',
                  },
                ],
              },
            ],
          },
          {
            component: TableCardMonopolization,
          },
          // {
          //   title: 'Возможности',
          //   labels: ['Руб'],
          //   shortcut: true,
          //   tabs: [
          //     {
          //       info: [
          //         {
          //           label: 'Упущенные&nbsp;заказы',
          //           key: 'lost_orders_rub',
          //           type: 'currency',
          //           isQuestion: true,
          //           questionText:
          //             'Заказы, не полученные из-за отсутствия товаров на складе. Рассчитывается на основании средних продаж в сутки за последние 14 дней',
          //         },
          //         {
          //           label: 'Потенциал',
          //           key: 'potential_rub',
          //           type: 'currency',
          //           isQuestion: true,
          //           questionText:
          //             'Ожидаемая выручка при условии постоянного наличия всех товаров на складе. Сумма заказов товаров + упущенные заказы.',
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      ]}
    />
  );
};

export default TableCardList;
