import cn from 'classnames';
import PropTypes from 'prop-types';
/**
 * @return {JSX.Element} Иконку "Крестик"
 *
 * @param {import('react').HTMLAttributes<SVGElement>} props
 * */
export function CrossIcon({ className = 'h-3 w-3', ...svgProps }) {
  const fill = svgProps ? svgProps.fillProps : 'currentColor';

  return (
    <svg
      className={cn(' text-gray-300', className)}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.192 1.692a.625.625 0 1 0-.884-.884L5 4.116 1.692.808a.625.625 0 1 0-.884.884L4.116 5 .808 8.308a.625.625 0 1 0 .884.884L5 5.884l3.308 3.308a.625.625 0 1 0 .884-.884L5.884 5l3.308-3.308Z"
        stroke={fill}
      />
    </svg>
  );
}

CrossIcon.propTypes = {
  className: PropTypes.string,
};
