import cn from 'classnames';

import { LIST_DATA } from 'constants/list';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
import { MODAL_MODES } from '../ListsModal/constants';

const ListActiveTypeSelector = ({ setModalMode, hideTabs = false, prefixTitle = 'Избранное' }) => {
  const {
    activeListType,
    countListsByType,
    handleActivationListType,
    getListByType,
    setSelectedList,
    whitelist,
  } = useUserList();

  const handleListActivation = (id) => {
    setModalMode(MODAL_MODES.NO_SELECTED);
    handleActivationListType(id);
  };

  const listTypes =
    whitelist && Array.isArray(whitelist) && whitelist.length > 0
      ? Object.values(LIST_DATA).filter(({ id }) => whitelist.includes(id))
      : LIST_DATA;
  return (
    <div className="flex divide-x divide-gray-230 gap-x-3">
      {/*<button*/}
      {/*  onClick={setFilterMode}*/}
      {/*  className="text-green border border-transparent transition-all focus:outline-none focus:border-green focus:text-gray-900 hover:text-gray-900"*/}
      {/*>*/}
      {/*  <DownIcon height={14} width={14} className="rotate-90" />*/}
      {/*</button>*/}

      <div className="gap-x-3 flex items-center leading-none">
        <h1 className="text-[18px] leading-none">{prefixTitle}</h1>

        {!hideTabs &&
          Object.values(listTypes)
            .reverse()
            .map((list) => (
              <button
                className={cn(
                  'text-[18px] font-bold hover:text-green focus:text-green transition-all relative top-[1px]',
                  'focus:outline-none font-sans',
                  {
                    ['text-green']: activeListType === list.id,
                    ['!text-gray-250']: activeListType !== list.id,
                  },
                )}
                key={list.id}
                onClick={() => {
                  handleListActivation(list.id);
                  const lists = getListByType(list.id) || [];
                  setSelectedList(lists?.[0]);
                }}
              >
                {list.label}

                <sup className="text-sm font-normal relative top-[-10px] left-[4px]">
                  {countListsByType(list.id)}
                </sup>
              </button>
            ))}
      </div>
    </div>
  );
};

ListActiveTypeSelector.propTypes = {
  setModalMode: PropTypes.func,
  prefixTitle: PropTypes.string,
  hideTabs: PropTypes.bool,
};
export default ListActiveTypeSelector;
