import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { BY_PHRASES, BY_PRODUCTS, BY_PRODUCTS_WORDS, TAB_FIRST } from 'constants/seo';
import { useQuerySeoFetch } from 'hooks/useFetchSeo';

const SEOSearchContext = React.createContext();
SEOSearchContext.displayName = 'SEO.Search.Context';

const PARAM_KEY = 'search';

const SEOSearchProvider = (props) => {
  let navigate = useNavigate();
  let { by, tab } = useParams();
  const queries = {
    [BY_PRODUCTS]: useQuerySeoFetch({ type: BY_PRODUCTS }),
    [BY_PRODUCTS_WORDS]: useQuerySeoFetch({ type: BY_PRODUCTS_WORDS }),
    [BY_PHRASES]: useQuerySeoFetch({ type: BY_PHRASES }),
  };

  const setSearchParams = (values) => {
    let params = new URLSearchParams(values.map((s) => [PARAM_KEY, s]));
    navigate({
      pathname: `${TAB_FIRST}`,
      search: `?${params.toString()}`,
    });
  };

  const getSearchParams = () => {
    const url = new URL(window.location.href);
    return url.searchParams.getAll(PARAM_KEY);
  };

  let params = getSearchParams();
  if (by === BY_PRODUCTS && tab === BY_PRODUCTS_WORDS) {
    by = tab;
  }

  const fetchQuery = queries[by];

  const queryParams = { type: by, tab, values: params };

  const getSearchString = () => {
    return `?${PARAM_KEY}=${params.toString()}`;
  };

  let paramsString = getSearchString();

  const value = {
    by,
    tab,
    setSearchParams,
    params,
    queries,
    fetchQuery,
    queryParams,
    paramsString,
  };

  return <SEOSearchContext.Provider value={value} {...props} />;
};

const useSEOSearch = () => {
  return React.useContext(SEOSearchContext);
};

export { SEOSearchProvider, useSEOSearch };
