import CodeVerification from 'components/CodeVerification';
import ResendTimer from 'components/ResendTimer';
import { useAuth } from 'context/auth.context';
import { setToken } from 'context/auth.methods';
import { clearUTMParametersFromLocalStorage } from 'helpers/utm';
import { useCallback, useState } from 'react';
import { sendToGA, sendToYM } from 'utils/gtag_ym';

const CheckPhoneCode = () => {
  const { checkPhoneNumber, registerPhoneNumber, phoneNumber, setStep, signUpFormData } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onChange = useCallback(
    (code) => {
      const { name, email } = signUpFormData || {};
      setIsLoading(true);
      registerPhoneNumber({
        phone_number: phoneNumber,
        code: code,
        name,
        email,
      })
        .then((data) => {
          const { user_id, token } = data || {};
          setToken({ access_token: token });

          setError(null);
          setStep(3);

          clearUTMParametersFromLocalStorage();

          sendToGA('mainEvent', {
            eventCategory: 'Registration',
            eventAction: 'Complete',
            eventLabel: 'LoginPassword',
            user_id,
          });

          sendToYM('successful_registartion');
        })
        .catch((error) => {
          const response = error.response || {};
          const data = response.data || {};
          const message = data.message ?? 'Ошибка';
          setError(message);

          sendToGA('mainEvent', {
            eventCategory: 'Message - Front',
            eventAction: 'Message',
            eventLabel: 'RegistrationError "' + message + '"',
            user_id: 'none',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [phoneNumber, registerPhoneNumber, setStep, signUpFormData],
  );

  const resend = () => {
    checkPhoneNumber({
      phone_number: phoneNumber,
    });
  };
  return (
    <>
      <h1>Подтверждение номера</h1>

      <div className={'mb-5'}>
        <div className="relative md:flex md:-mx-36 md:px-36 mt-5">
          <label
            className="block mb-2 text-black flex-auto pr-3 md:absolute md:left-0 md:w-36 md:h-full md:flex md:items-center md:justify-end md:mb-0"
            htmlFor="phone"
          >
            Введите SMS-код
          </label>

          <CodeVerification onChange={onChange} loading={isLoading} />
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
      <ResendTimer onResend={resend} disabled={isLoading} />
      <button className="text-green my-2" onClick={() => setStep(0)}>
        Ввести другой номер
      </button>
    </>
  );
};

export default CheckPhoneCode;
