import DownIcon from 'assets/icons/DownIcon';
import StarIcon from 'assets/icons/StarIcon';
import { CrossIcon } from 'assets/images';
import cn from 'classnames';
import { useUI } from 'context/ui.context';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
const ListModeActivateButton = ({
  onClick,
  label = `Избранное`,
  allowCreateFromFilter = false,
}) => {
  const {
    activeList,
    activeListData,
    setSelectedList,
    listsByActiveType,
    isListsLoading,
    resetActiveList,
  } = useUserList();
  const { isMobile } = useUI();
  const handleClick = () => {
    onClick && onClick();
    if (!activeList && !isMobile) {
      setSelectedList(listsByActiveType?.[0]);
    }
  };

  const resetList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetActiveList();
  };
  return (
    <button
      onClick={handleClick}
      className={cn(
        `bg-white border border-gray-250 flex items-center self-stretch
    px-1.5 lg:px-2 py-1 space-x-0.5 lg:space-x-1.5 rounded 
    transition-all hover:text-green hover:border-green focus:text-green focus:border-green
    `,
        {
          'border-green': activeList,
        },
      )}
    >
      {!activeList && allowCreateFromFilter && !isListsLoading ? (
        <StarIcon className="text-gray-250 h-5 w-5" />
      ) : (
        <StarIcon className="text-yellow-bright h-5 w-5" />
      )}

      <span className="hidden lg:inline">
        {activeList ? (
          <div className="flex">
            <span className="block text-green">Применен список:</span>
            <span className="block ml-1 text-gray-700 max-w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
              {activeListData.name}
            </span>
          </div>
        ) : allowCreateFromFilter && !isListsLoading ? (
          'Сохранить как список'
        ) : (
          label
        )}
      </span>

      <DownIcon height={14} width={14} className="rotate-[-90deg]" />
      {activeList && (
        <span
          className="border-l border-l-gray-[#E3E3E3] ml-2 pl-2 self-center"
          onClick={resetList}
        >
          <span className="bg-[#E3E3E3] rounded-full block p-1">
            <CrossIcon className={'h-2 w-2'} width={10} height={10} />
          </span>
        </span>
      )}
    </button>
  );
};
ListModeActivateButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  allowCreateFromFilter: PropTypes.bool,
};
export default ListModeActivateButton;
