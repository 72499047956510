import { COLUMNS } from 'constants/table';

function formatValue(value, leaveEmpty) {
  let result = value;

  if (!value && !leaveEmpty) {
    return 0;
  }

  if (!value && leaveEmpty) {
    return null;
  }

  if (value === '-' || value === ' ') {
    return null;
  }

  if (!Number.isInteger(+value) && typeof value !== 'number') return null;

  if (typeof value === 'string') {
    result = parseInt(result);
  }

  result = Math.round(value * 10) / 10;

  return result.toLocaleString('ru');
}

function formatString({ params, prefix = '', postfix, skipSpace = false }) {
  const value = formatValue(params.value, params.column?.cellRendererParams?.leaveEmpty || false);

  if (!value) {
    return null;
  }

  return `${prefix}${prefix ? ':' : ''} ${value}${skipSpace ? '' : ' '}${postfix}`;
}

function formatPredictString({ params, prefix = '', postfix, skipSpace = false }) {
  const value = formatValue(params.value, params.column?.cellRendererParams?.leaveEmpty || false);
  if (!value || value === '-') {
    const predictKey =
      params.colDef.field === 'delivery_rub' ? 'predict_logistic' : 'predict_commission';
    const predictPrefix =
      params.colDef.field === 'delivery_rub' ? 'Прогнозная логистика' : 'Прогнозная комиссия';
    return `${predictPrefix}${predictPrefix ? ':' : ''} ${
      formatValue(
        params.data[predictKey],
        params.column?.cellRendererParams?.leaveEmpty || false,
      ) || 0
    }${skipSpace ? '' : ' '}${postfix}`;
  }

  return `${prefix}${prefix ? ':' : ''} ${value || 0}${skipSpace ? '' : ' '}${postfix}`;
}

function formatWithPercentTooltip({ params, prefix = '', postfix, percentLabel }) {
  const value = formatValue(params.value, params.column?.cellRendererParams?.leaveEmpty || false);

  if (!value) {
    return null;
  }

  let result = formatString({ prefix, params, postfix });

  const key = params?.column?.colId;

  let percent = params?.data?.[`${key}_percent`];
  percent = percent || percent === 0 ? formatValue(percent) : null;

  if (percent) {
    result += `\n ${percentLabel || 'Доля от фильтров'}: ${percent}%`;
  }

  return result;
}

function formatTooltipStrings(strings) {
  return strings.reduce((acc, { prefix, postfix, value }) => {
    let result = '';

    value = formatValue(value);

    if (acc && value) {
      result += '\n';
    }

    if (value) {
      result += `${prefix}: ${value}${postfix}`;
    }

    return `${acc}${result}`;
  }, '');
}

const simpleTooltipFormatter =
  (prefix) =>
  ({ value }) => {
    if (!value) return null;

    return `${prefix}${prefix ? ':' : ''} ${value}`;
  };

const splitByCommasTooltipFormatter = ({ value }) => {
  if (!value) return '';

  return value?.replaceAll(',', '\n');
};

const fromKeyArrayTooltipFormatter =
  (key) =>
  ({ value, ...otherProps }) => {
    if (!value) return '';

    const { data } = otherProps;
    return data[key]?.join('\n') ?? value;
  };

const currencyTooltipFormatter =
  (prefix, isPredict = false) =>
  (params) => {
    return isPredict
      ? formatPredictString({ prefix, params, postfix: '₽' })
      : formatString({ prefix, params, postfix: '₽' });
  };

const amountTooltipFormatter = (prefix) => (params) => {
  return formatString({ prefix, params, postfix: 'шт' });
};

const percentTooltipFormatter = (prefix) => (params) => {
  return formatString({ prefix, params, postfix: '%', skipSpace: true });
};
const avoidPostfixTooltipFormatter = (prefix) => (params) => {
  return formatString({ prefix, params, postfix: '', skipSpace: true });
};

const currencyWithPercentTooltipFormatter =
  (prefix, percentLabel = '') =>
  (params) => {
    return formatWithPercentTooltip({ params, prefix, postfix: '₽', percentLabel });
  };

const amountWithPercentTooltipFormatter =
  (prefix, percentLabel = '') =>
  (params) => {
    return formatWithPercentTooltip({ params, prefix, postfix: 'шт', percentLabel });
  };

const skuTooltipFormatter = ({ data }) => {
  const strings = [
    {
      prefix: 'Товаров в наличии',
      postfix: ' шт',
      value: data?.[COLUMNS.SKU_QTY],
    },
    {
      prefix: 'Товаров с заказами',
      postfix: ' шт',
      value: data?.[COLUMNS.ACTIVE_SKU_QTY],
    },
    {
      prefix: 'Доля товаров с заказами',
      postfix: '%',
      value: data?.[COLUMNS.ACTIVE_SKU_PERCENT],
    },
  ];

  return formatTooltipStrings(strings);
};

const priceTooltipFormatter = ({ data, value }) => {
  const strings = [
    {
      prefix: 'Текущая цена товара',
      postfix: ' ₽',
      value,
    },
    {
      prefix: 'Цена заказанного товара',
      postfix: ' ₽',
      value: data?.[COLUMNS.AVG_ORDER_PRICE],
    },
    {
      prefix: 'Мин. цена товара',
      postfix: ' ₽',
      value: data?.[COLUMNS.MIN_PRICE],
    },
    {
      prefix: 'Макс. цена товара',
      postfix: ' ₽',
      value: data?.[COLUMNS.MAX_PRICE],
    },
  ];

  return formatTooltipStrings(strings);
};

const sellersTooltipFormatter = ({ data }) => {
  const strings = [
    {
      prefix: 'Продавцы в наличии',
      postfix: ' шт',
      value: data?.[COLUMNS.SELLERS_QTY] || data?.[COLUMNS.SELLERS_WITH_PRODUCT],
    },
    {
      prefix: 'Продавцы с заказами',
      postfix: ' шт',
      value:
        data?.[COLUMNS.ACTIVE_SELLERS_QTY] ||
        data?.[COLUMNS.ACTIVE_SELLERS_QTY_2] ||
        data?.[COLUMNS.SELLERS_WITH_ORDERS_],
    },
    {
      prefix: 'Доля продавцов с заказами',
      postfix: '%',
      value: data?.[COLUMNS.ACTIVE_SELLERS_PERCENT],
    },
  ];

  return formatTooltipStrings(strings);
};

const avgOrdersSellerTooltipFormatter = ({ data, value }) => {
  const strings = [
    {
      prefix: 'В сутки у продавца',
      postfix: ' ₽',
      value: value,
    },
    {
      prefix: 'В сутки у продавца',
      postfix: ' шт',
      value: data?.[COLUMNS.AVG_ORDERS_1SELLER_QTY],
    },
  ];

  return formatTooltipStrings(strings);
};

const brandsQtyTooltipFormatter = ({ data }) => {
  const strings = [
    {
      prefix: 'Бренды в наличии',
      postfix: ' шт',
      value: data?.[COLUMNS.BRANDS_QTY] || data?.[COLUMNS.BRANDS_WITH_PRODUCTS],
    },
    {
      prefix: 'Бренды с заказами',
      postfix: ' шт',
      value: data?.[COLUMNS.ACTIVE_BRANDS_QTY],
    },
    {
      prefix: 'Доля брендов с заказами',
      postfix: '%',
      value: data?.[COLUMNS.ACTIVE_BRANDS_PERCENT],
    },
  ];

  return formatTooltipStrings(strings);
};

const avgOrderSkuTooltipFormatter = ({ data }) => {
  const strings = [
    {
      prefix: 'Один товар заказывали',
      postfix: ' раз',
      value: data?.[COLUMNS.AVG_ORDERS_1SKU_QTY],
    },
    {
      prefix: 'Один товар заказывали',
      postfix: ' шт',
      value: data?.[COLUMNS.AVG_ORDERS_1SKU_RUB],
    },
  ];

  return formatTooltipStrings(strings);
};

const avgOrderPerDayTooltipFormatter = ({ data }) => {
  const strings = [
    {
      prefix: 'Заказов в сутки',
      postfix: ' ₽',
      value: data?.[COLUMNS.AVG_ORDERS_PER_DAY_RUB],
    },
    {
      prefix: 'Заказов в сутки',
      postfix: ' шт',
      value: data?.[COLUMNS.AVG_ORDERS_PER_DAY_QTY],
    },
  ];

  return formatTooltipStrings(strings);
};

const potentialTooltipFormatter = ({ data, value }) => {
  const strings = [
    {
      prefix: 'Потенциал',
      postfix: ' ₽',
      value: value,
    },
    {
      prefix: 'Потенциал',
      postfix: ' шт',
      value: data?.[COLUMNS.POTENTIAL_QTY],
    },
  ];

  return formatTooltipStrings(strings);
};

const lostOrdersTooltipFormatter = ({ data, value }) => {
  const strings = [
    {
      prefix: 'Упущено заказов',
      postfix: ' ₽',
      value: value,
    },
    {
      prefix: 'Упущено заказов',
      postfix: ' шт',
      value: data?.[COLUMNS.LOST_ORDERS_QTY],
    },
    {
      prefix: 'Доля упущенных заказов',
      postfix: '%',
      value: data?.[COLUMNS.LOST_ORDERS_PERCENT],
    },
  ];

  return formatTooltipStrings(strings);
};

const priceAverageTooltipFormatter = ({ data, value }) => {
  const strings = [
    {
      prefix: 'Средняя цена товара',
      postfix: ' ₽',
      value,
    },
    {
      prefix: 'Цена заказанного товара',
      postfix: ' ₽',
      value: data?.[COLUMNS.AVG_ORDER_PRICE],
    },
  ];

  return formatTooltipStrings(strings);
};

const simpleBooleanTooltipFormatter =
  (prefix) =>
  ({ value }) => {
    if (value === undefined) return null;

    return `${prefix}${prefix ? ':' : ''} ${value ? 'дa' : 'нет'}`;
  };

export {
  amountTooltipFormatter,
  amountWithPercentTooltipFormatter,
  avgOrderPerDayTooltipFormatter,
  avgOrderSkuTooltipFormatter,
  avgOrdersSellerTooltipFormatter,
  avoidPostfixTooltipFormatter,
  brandsQtyTooltipFormatter,
  currencyTooltipFormatter,
  currencyWithPercentTooltipFormatter,
  formatTooltipStrings,
  fromKeyArrayTooltipFormatter,
  lostOrdersTooltipFormatter,
  percentTooltipFormatter,
  potentialTooltipFormatter,
  priceAverageTooltipFormatter,
  priceTooltipFormatter,
  sellersTooltipFormatter,
  simpleBooleanTooltipFormatter,
  simpleTooltipFormatter,
  skuTooltipFormatter,
  splitByCommasTooltipFormatter,
};
