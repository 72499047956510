import { useAuth } from 'context/auth.context';
import { saveUTMParametersToLocalStorage } from 'helpers/utm';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';
import CheckPhoneCode from './CheckPhoneCode';
import SignUpCompleted from './SignUpCompleted';
import SignUpStart from './SignUpStart';

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get('phone_number') || false;

  const { step, setStep, setPhoneNumber } = useAuth();

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
      setStep(1);
    }
    return () => {
      setStep(0);
    };
  }, [setStep, phoneNumber, setPhoneNumber]);

  useEffect(() => {
    saveUTMParametersToLocalStorage();
  }, []);

  if (step === 1) {
    return <CheckPhoneCode />;
  } else if (step === 3) {
    return <SignUpCompleted />;
  } else {
    return <SignUpStart />;
  }
};

export default SignUp;
