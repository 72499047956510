export function isEmptyOrWhitespace(str) {
  if (!str) {
    return true;
  }
  return !str.trim();
}

export function validateFormFields(fields, values) {
  let isValid = true;
  let errors = {};

  if (Array.isArray(fields)) {
    fields.forEach(({ field, rules }) => {
      let value = values[field] || null;

      const fieldErrors = [];

      rules.forEach((ruleData) => {
        const { required, rule, min, max, regex, message, whitespace = true } = ruleData || {};

        if (whitespace) {
          value = value.trim();
        }

        if (required && isEmptyOrWhitespace(value)) {
          fieldErrors.push('Поле обязательно для заполнения');
        }

        switch (rule) {
          case 'length':
            if (value && (value.length < min || value.length > max)) {
              fieldErrors.push(message);
            }
            break;
          case 'regex':
            if (value && !regex.test(value)) {
              fieldErrors.push(message);
            }
            break;
          case 'email':
            {
              const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (value && !emailRegex.test(value)) {
                fieldErrors.push(message);
              }
            }
            break;
          default:
            break;
        }
      });

      if (fieldErrors.length > 0) {
        isValid = false;
        errors[field] = fieldErrors;
      }
    });
  }
  return { isValid, errors };
}

export const getErrorsFromResponse = (message, errors = {}) => {
  if (!Object.keys(errors).length) {
    return message;
  }
  const errorMessages = Object.values(errors).flat();
  return errorMessages.join(', ');
};
