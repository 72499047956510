import ButtonBack from 'components/ButtonBack';
import NavList from 'components/NavList';
import Table from 'components/Table';
import {
  BY_PHRASES,
  BY_PRODUCTS,
  BY_PRODUCTS_WORDS,
  NESTED,
  /*NESTED,*/ TAB_FIRST /*TAB_SECOND*/,
  TAB_SECOND,
} from 'constants/seo';
import { COLUMNS, TABLE_TYPE } from 'constants/table';
import { useAuth } from 'context/auth.context';
import { useSEOSearch } from 'context/seo.search.context';
import { useQuerySeoData } from 'hooks/useFetchSeo';
import { useState } from 'react';
import { Link } from 'react-router';
// import { declOfNum } from 'utils/client';
// import { PER_PAGE } from 'helpers/generateGridConfig';

const Search = () => {
  const { queryParams } = useSEOSearch();
  const { isAdmin } = useAuth();
  const [page, setPage] = useState(1);

  const { data, isFetching, isLoading } = useQuerySeoData({
    ...queryParams,
    externalFilter: {
      per_page: 50,
      page: page,
    },
  });

  const { by, tab } = useSEOSearch();

  const getTabs = () => {
    const tabs = [
      // {
      //   link: `/seo/${by}/${TAB_FIRST}`,
      //   title: 'Запросы',
      //   search: location.search,
      // },
    ];
    if (isAdmin) {
      tabs.push({
        link: `/seo/${BY_PRODUCTS}/${TAB_FIRST}`,
        title: 'Запросы',
        search: location.search,
      });

      tabs.push({
        link: `/seo/${BY_PRODUCTS}/${TAB_SECOND}`,
        title: 'Слова',
        search: location.search,
      });
    }
    return tabs;
  };
  // const tabFirstCount = data?.table_1.length || 0;
  // const tabSecondCount = data?.table_2.length || 0;
  //
  // const FilterExtra = () => {
  //   if (tab === TAB_FIRST && tabFirstCount) {
  //     return (
  //       <span className="text-gray-400">
  //         Всего {tabFirstCount} {declOfNum(tabFirstCount, ['фраза', 'фразы', 'фраз'])}
  //       </span>
  //     );
  //   }
  //   if (tab === TAB_SECOND && tabSecondCount) {
  //     return (
  //       <span className="text-gray-400">
  //         Всего {tabSecondCount} {declOfNum(tabSecondCount, ['фраза', 'фразы', 'фраз'])}
  //       </span>
  //     );
  //   }
  //
  //   return null;
  // };

  let totalPages = data?.pagination?.total_pages || data?.pagination?.total || 0;
  let dataSource = data?.table_data || data?.table_1 || [];
  let tabs = [];
  if ([BY_PRODUCTS, BY_PRODUCTS_WORDS].includes(by)) {
    tabs = getTabs();
    switch (BY_PRODUCTS) {
      case BY_PRODUCTS:
        dataSource = data?.table1?.data;
        totalPages = data?.table1?.pagination?.total || 0;
        break;
    }
  }

  const isByProducts = [BY_PRODUCTS_WORDS, BY_PRODUCTS].includes(by);
  return (
    <>
      <div className="wrapper-wide mt-1">
        <div className="self-start mt-4 mb-6">
          <ButtonBack
            label="Вернуться назад"
            backTo={isByProducts ? `/seo/${BY_PRODUCTS}` : `/seo/${BY_PHRASES}`}
          />
        </div>

        <h1 className="mt-4">
          Расширение запросов
          <span className="text-gray-250 px-3 font-normal">/</span>
          {isByProducts && <Link to={`/seo/${BY_PRODUCTS}`}>По товарам</Link>}
          {by === BY_PHRASES && <Link to={`/seo/${BY_PHRASES}`}>По поисковым фразам</Link>}
        </h1>

        <div className="text-gray-400 mt-1.5 mb-8 max-w-[800px]">
          {isByProducts && (
            <>
              Все запросы, по которым встретили переданные SKU в поисковой выдачи за последние 30
              дней.
            </>
          )}

          {by === BY_PHRASES && (
            <>
              Все SKU, которые ранжировались по переданным запросам за последние 7 дней с позицией в
              выдаче не ниже 30, затем мы найдем запросы, по которым встретили найденные SKU в
              поисковой выдаче за последние 30 дней.
            </>
          )}
        </div>

        {tabs.length > 0 && <NavList classList="mb-4" items={tabs} />}
      </div>

      {tab === TAB_FIRST && (
        <Table
          data={dataSource}
          // pagination={true}
          totalPages={totalPages}
          isLoading={isLoading || isFetching}
          type={TABLE_TYPE[TAB_FIRST]}
          autoSizeColumns={[COLUMNS.PHRASE_NAME]}
          hideFilterPanel={true}
          noRowsText={'Данные отсутствуют'}
          paginationData={{
            page,
            setPage,
          }}
          paginationOnBack
          // footerExtra={<FilterExtra />}
        />
      )}

      {tab === TAB_SECOND && (
        <Table
          data={data || []}
          // pagination={true}
          totalPages={data?.length || 0}
          isLoading={isLoading || isFetching}
          type={TABLE_TYPE[TAB_SECOND]}
          typeNested={TABLE_TYPE[`${TAB_SECOND}${NESTED}`]}
          nested={COLUMNS.SIMILAR_PHRASES}
          autoSizeColumns={[COLUMNS.WORD]}
          hideFilterPanel={true}
          autoSizeNestedColumns={[COLUMNS.PHRASE_NAME]}
          noRowsText={'Данные отсутствуют'}
          nestedCheckbox={true}
          nestedParentColumn={COLUMNS.WORD}
          // footerExtra={<FilterExtra />}
        />
      )}
    </>
  );
};

export default Search;
