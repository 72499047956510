import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ProductSearchForm from './ProductSearchForm';

const ProductSearchModal = ({ isOpen = true, title, closeModal, submitModal }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleSubmit = () => {
    submitModal(selectedProducts);
    closeModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      closeModal={closeModal}
      submitModal={handleSubmit}
      classNames="!p-0"
      headerClassNames="!px-5"
      width={700}
      submitButtonText={'Добавить'}
      submitButtonDisabled={selectedProducts.length === 0}
    >
      <ProductSearchForm
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
      />
    </Modal>
  );
};

ProductSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  submitModal: PropTypes.func,
};

export default ProductSearchModal;
