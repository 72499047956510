import { TABLE_TYPE } from 'constants';
import { STALE_TIME } from 'constants/query';
import {
  BY_PHRASES,
  BY_PRODUCTS,
  BY_PRODUCTS_WORDS,
  SEO_BY_PHRASE,
  SEO_CLUSTERS_BY_ARTICLE,
  SEO_CLUSTERS_BY_GROUP,
  SEO_COMPARE,
  SEO_HINTS,
  SEO_MONITORING_PHRASES,
  SEO_MONITORING_PRODUCTS,
  SEO_SMART,
  SEO_SMART_DESCRIPTION_WORDS,
} from 'constants/seo';
import { queryClient } from 'context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useQuery } from 'react-query';
import apiClient from 'services/baseClient';

const action = {
  [BY_PRODUCTS]: (props) => () => apiClient.getSearchAmount(props),
  [BY_PRODUCTS_WORDS]: (props) => () => apiClient.getSearchAmountWords(props),
  // [BY_PHRASES]: (props) => () => apiClient.getSearchProduct(props),
  [BY_PHRASES]: (props) => () => apiClient.getSearchPhrases(props),
  [SEO_COMPARE]: (props) => () => apiClient.seoCompare(props),
  [SEO_MONITORING_PRODUCTS]: (props) => () => apiClient.seoMonitoringProducts(props),
  [SEO_MONITORING_PHRASES]: (props) => () => apiClient.seoMonitoringPhrases(props),
  [SEO_BY_PHRASE]: (props) => () => apiClient.seoByPhrase(props),
  [SEO_HINTS]: (props) => () => apiClient.seoHints(props),
  [SEO_SMART]: (props) => () => apiClient.seoSmart(props),
  [SEO_SMART_DESCRIPTION_WORDS]: (props) => () => apiClient.seoSmartDescriptionWords(props),
  // clusters
  [SEO_CLUSTERS_BY_ARTICLE]: (props) => () => apiClient.getSearchAmount(props),
  [SEO_CLUSTERS_BY_GROUP]: (props) => () => apiClient.getClustersByGroup(props),
  [TABLE_TYPE.SEO_SEARCH]: (props) => () => apiClient.getSeoSearch(props),
};

const key = {
  [BY_PRODUCTS]: 'product',
  [BY_PRODUCTS_WORDS]: 'product',
  [BY_PHRASES]: 'search_phrases',
};

const getFilter = ({ type, values, externalFilter }) => {
  const filter = { platform: [2] };
  const dataKey = key?.[type];

  if (dataKey) {
    filter[dataKey] = values;
  }

  if (externalFilter) {
    return { ...filter, ...externalFilter };
  }

  return filter;
};
const getQueryKey = ({ type, tab, filter, restricts, sort, order, page, per_page }) => [
  type,
  tab,
  filter,
  restricts,
  sort,
  order,
  page,
  per_page,
];

export const useQuerySeoFetch =
  ({ type }) =>
  (values) => {
    const filter = getFilter({ type, values });
    const queryKey = getQueryKey({ type, filter });
    const queryFn = action?.[type];

    return queryClient.fetchQuery(queryKey, queryFn ? queryFn({ filter }) : null, {
      refetchOnmount: false,
      staleTime: STALE_TIME,
    });
  };

const useRestrictsOption = () => {
  const { filterRange } = useFilterReducer();

  if (!filterRange) return {};

  return filterRange;
};

export const useQuerySeoData = ({ type, tab, values, externalFilter = {} }) => {
  const restricts = useRestrictsOption(type);
  let {
    filter: { sort: sortFromFilter, order },
    isLoaded,
  } = useFilterReducer();

  const sort = sortFromFilter || 'frequency_wb';
  const filter = getFilter({
    type,
    values,
  });
  const queryKey = getQueryKey({
    type,
    tab,
    filter,
    restricts,
    externalFilter,
    page: externalFilter.page,
    per_page: externalFilter.per_page,
    sort,
    order,
  });
  const queryFn = action?.[type];
  return useQuery({
    queryKey,
    queryFn: queryFn ? queryFn({ filter, ...externalFilter, sort, order, restricts }) : null,
    refetchOnmount: false,
    staleTime: STALE_TIME,
    enabled: isLoaded,
  });
};

export const useQuerySeo = ({
  type,
  filter,
  enabled = false,
  restricts,
  warehouseType,
  sort,
  order,
}) => {
  const queryKey = getQueryKey({ type, filter, restricts, sort, order });
  const queryFn = action?.[type];
  return useQuery({
    queryKey,
    queryFn: queryFn ? queryFn({ filter, restricts, warehouseType, sort, order }) : null,
    enabled: enabled,
    staleTime: STALE_TIME,
    //
    // refetchOnmount: false,
    // cacheTime: STALE_TIME,
    // retry: 3,
    // keepPreviousData: true,
  });
};
