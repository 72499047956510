import ListModeActivateButton from 'components/ListModeActivateButton';
import ListsModal from 'components/ListsModal';
import { FILTER_MODE, LIST_MODE, MODAL_MODES } from 'components/ListsModal/constants';
import { LIST_TYPE } from 'constants/list';
import { useComparisonContext } from 'context/comparison.context';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

const ProductListModalButton = ({ label = 'Списки' }) => {
  const [mode, setMode] = useState(FILTER_MODE);
  const { activeList, setWhitelist, addFavourite } = useUserList();
  const { updateProducts, hasProducts, products } = useComparisonContext();
  const openCreateListByType = !activeList && hasProducts;

  const newListCallback = useCallback(
    (createdListId) => {
      if (createdListId) {
        addFavourite({
          listId: createdListId,
          id: products,
          type: LIST_TYPE.BY_PRODUCTS,
        });
      }
    },
    [products, addFavourite],
  );

  const setListMode = () => {
    // This logic seems to be for activating a list and setting mode
    setMode(LIST_MODE);
    setWhitelist([LIST_TYPE.BY_PRODUCTS]);
  };

  const setProductsFromList = (selectedList) => {
    const { id: activeListId } = activeList || {};
    const { id, items = [] } = selectedList || {};
    if (activeListId != id) updateProducts(items?.map(({ id }) => id));
  };

  return (
    <>
      <ListModeActivateButton
        onClick={setListMode}
        label={label}
        allowCreateFromFilter={hasProducts}
      />
      {mode !== FILTER_MODE && (
        <ListsModal
          mode={mode}
          FILTER_MODE={FILTER_MODE}
          setMode={setMode}
          LIST_MODE={LIST_MODE}
          submitButtonText={'Сравнить'}
          disableRedirect
          modalTitle={'Списки'}
          onComplete={setProductsFromList}
          modalMode={openCreateListByType ? MODAL_MODES.NEW : undefined}
          newListCallback={newListCallback}
        />
      )}
    </>
  );
};

ProductListModalButton.propTypes = {
  label: PropTypes.string,
};

export default ProductListModalButton;
