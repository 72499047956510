import useLocalStorage from 'hooks/useLocalStorage';
import React, { useEffect, useState } from 'react';
import baseClient from 'services/baseClient';
import { useAuth } from './auth.context';

const UserProductsContext = React.createContext();
UserProductsContext.displayName = 'User.Products.Context';

const UserProductsProvider = (props) => {
  const { user } = useAuth();
  const [userProducts, setUserProducts] = useState([]);
  const [showMyProducts = false, setShowMyProducts] = useLocalStorage(`show_user_products`);

  const toggleMyProducts = () => {
    setShowMyProducts(!showMyProducts);
  };

  useEffect(() => {
    const loadProducts = async () => {
      const response = await baseClient.getUserProducts();

      const { data } = response || {};
      const userProducts = data || [];
      if (userProducts.length === 0) {
        return [];
      }
      return userProducts;
    };

    const fetchProducts = async () => {
      if (showMyProducts) {
        const products = await loadProducts();
        setUserProducts(products);
      }
    };
    if (user) fetchProducts();
  }, [showMyProducts, user]);

  const getRowClass = (params) => {
    if (
      !showMyProducts ||
      !userProducts ||
      (Array.isArray(userProducts) && userProducts.length === 0)
    ) {
      return null;
    }
    if (userProducts.includes(params.data.external_id)) {
      return 'row--highlighted-green';
    }

    return null;
  };

  const refreshHighlightedRows = (gridApi) => {
    if (!gridApi) return;

    const rowsToRefresh = [];
    gridApi.forEachNode((node) => {
      if (node.data) {
        const shouldHighlight = userProducts?.includes(node.data.external_id);
        if (shouldHighlight) {
          rowsToRefresh.push(node);
        }
      }
    });

    if (rowsToRefresh.length > 0) {
      gridApi.redrawRows({
        rowNodes: rowsToRefresh,
      });
    }
  };

  const value = {
    userProducts,
    setUserProducts,
    showMyProducts,
    toggleMyProducts,
    getRowClass,
    refreshHighlightedRows,
  };

  return <UserProductsContext.Provider value={value} {...props} />;
};

const useUserProducts = () => {
  return React.useContext(UserProductsContext);
};

export { UserProductsProvider, useUserProducts };
