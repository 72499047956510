import cn from 'classnames';
import PropTypes from 'prop-types';
import { lazy, useState } from 'react';

import Filter from 'components/Filter';
import FilterInner from 'components/FilterInner';
import ListModeActivateButton from 'components/ListModeActivateButton';
import TableHeaderPeriodControls from 'components/TableHeaderPeriodControls';
import { useAnalyticType } from 'context/analytic.type.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import { isSeasonPage } from 'utils/pages';
import ListsModal from '../ListsModal';
import { PanelTitle } from './components';
import { PAGE_TITLE_STYLES } from './components/Navigation';

const TableHeaderDateControls = lazy(() => import('components/TableHeaderDateControls'));

const PanelButton = ({ children, onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'flex flex-none group leading-none items-center font-bold text-lg lg:text-2xl transition',
        {
          ['order-1 pointer-events-none']: active,
          ['order-2 border-l border-gray-230 pl-4 ml-4 text-gray-250 hover:text-black']: !active,
        },
      )}
    >
      {children}
    </button>
  );
};
PanelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

const FILTER_MODE = 'filter-mode';
const LIST_MODE = 'list-mode';
const INNER_ANALYTICS_MODE = 'inner-analytics-mode';

const Panel = ({ isDateControlsShown, isPeriodControlsShown }) => {
  const { isInner } = useAnalyticType();

  const { error } = useFilterReducer();
  const { isMobile } = useUI();

  const [mode, setMode] = useState(isInner ? INNER_ANALYTICS_MODE : FILTER_MODE);

  const setListMode = () => setMode(LIST_MODE);

  const isSeasonedPage = isSeasonPage();

  return (
    <div className={cn('bg-white pt-2 lg:pt-3 pb-2 lg:pb-3')}>
      <div className="wrapper-wide flex justify-between items-center">
        {!isInner && <PanelTitle />}
        {isPeriodControlsShown && isSeasonedPage && (
          <div className="ml-4">
            <TableHeaderPeriodControls layout={'group'} />
          </div>
        )}
        {isInner && <h1 className={PAGE_TITLE_STYLES}>Аналитика собственных товаров</h1>}
        {/*{mode === LIST_MODE && <ListActiveTypeSelector setFilterMode={setFilterMode} />}*/}
        <div className="flex ml-auto items-center pl-2 space-x-3">
          {!isMobile && !isInner && <ListModeActivateButton onClick={setListMode} />}

          {!isMobile && isDateControlsShown && <TableHeaderDateControls />}

          {isPeriodControlsShown && !isSeasonedPage && <TableHeaderPeriodControls />}
        </div>
      </div>

      <div className="pt-4 lg:pt-3">
        <div className="wrapper-wide flex gap-2">
          {!error && !isInner && <Filter setListMode={setListMode} />}

          {!error && isInner && <FilterInner />}

          {/*{mode === LIST_MODE && <ListItemsRow />}*/}
          <ListsModal
            mode={mode}
            FILTER_MODE={FILTER_MODE}
            setMode={setMode}
            LIST_MODE={LIST_MODE}
            disableRedirect={isSeasonedPage}
          />
        </div>
      </div>
    </div>
  );
};

Panel.propTypes = {
  calculatedHeight: PropTypes.number,
  isSticky: PropTypes.bool,
  isPeriodControlsShown: PropTypes.bool,
  isDateControlsShown: PropTypes.bool,
};

export default Panel;
