import { PAGE_BRANDS, PAGE_MAIN, PAGE_PRODUCTS, PAGE_SELLERS } from 'constants';

export const getResultLink = (type) => {
  switch (type) {
    case 0: {
      return PAGE_PRODUCTS;
    }
    case 1: {
      return PAGE_SELLERS;
    }
    case 2: {
      return PAGE_BRANDS;
    }
    case 3: {
      return PAGE_MAIN;
    }
  }
};
