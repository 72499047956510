// контекст для контроля над логикой страницы Тренды
import moment from 'moment';
import React, { useState } from 'react';

import {
  CHART_TYPE,
  NAME_KEY,
  PERIOD_DAY,
  PERIOD_MONTH,
  PERIOD_WEEK,
  TYPES,
} from 'constants/chart';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { DATE_FORMAT } from 'context/filter/filter.context.reducer.methods';
import { transformDynamicChartsData } from 'helpers/transformChartsData';
import { useMultiChartFilteredData } from 'hooks/useMultiChartFilteredData';
import { useUserTariffs } from './user.tariffs.context';

const PERIODS = [PERIOD_DAY, PERIOD_WEEK];

const LABELS_KEYS = {
  ORDERS_RUB: 'orders_rub',
  ORDERS_QTY: 'orders_qty',
  EMPTY: 'empty',
  EMPTY1: 'empty1',
  EMPTY2: 'empty2',
  EMPTY3: 'empty3',
  EMPTY4: 'empty4',
  EMPTY5: 'empty5',
  SELLERS: 'sellers_qty',
  SELLERS_WITH_ORDERS: 'active_sellers_qty',
  SELLERS_ORDERS: 'seller_orders',
  SELLERS_PERCENT: 'active_sellers_percent',
  PRODUCTS_PERCENT: 'active_sku_percent',
  BRANDS_PERCENT: 'active_brands_percent',
  SELLERS_AVG: 'avg_orders_1seller_rub',
  AVERAGE_RUB: 'avg_price',
  AVERAGE_ORDER_RUB: 'avg_order_price',
  PRODUCTS: 'sku_qty',
  PRODUCTS_WITH_ORDERS: 'active_sku_qty',
  BRANDS: 'brands_qty',
  BRANDS_WITH_ORDERS: 'active_brands_qty',
};

const LABELS = {
  [LABELS_KEYS.ORDERS_RUB]: 'Заказы, руб.',
  [LABELS_KEYS.SELLERS]: 'Продавцы, шт.',
  // [LABELS_KEYS.SELLERS_ORDERS]: 'Заказы на продавца, руб.',
  [LABELS_KEYS.PRODUCTS]: 'Товары, шт.',
  [LABELS_KEYS.BRANDS]: 'Бренды, шт.',
  [LABELS_KEYS.AVERAGE_RUB]: 'Средняя цена товара',
  [LABELS_KEYS.ORDERS_QTY]: 'Заказы, шт.',
  [LABELS_KEYS.SELLERS_WITH_ORDERS]: 'Продавцы с заказами, шт.',
  [LABELS_KEYS.PRODUCTS_WITH_ORDERS]: 'Товары с заказами, шт.',
  [LABELS_KEYS.BRANDS_WITH_ORDERS]: 'Бренды с заказами, шт.',
  [LABELS_KEYS.AVERAGE_ORDER_RUB]: 'Цена заказанного товара',
  [LABELS_KEYS.EMPTY]: 'Empty',
  [LABELS_KEYS.SELLERS_PERCENT]: 'Доля продавцов с заказами, %',
  [LABELS_KEYS.PRODUCTS_PERCENT]: 'Доля товаров с заказами, %',
  [LABELS_KEYS.BRANDS_PERCENT]: 'Доля брендов с заказами, %',
  [LABELS_KEYS.EMPTY1]: 'Empty1',
  [LABELS_KEYS.EMPTY2]: 'Empty2',
  [LABELS_KEYS.SELLERS_AVG]: 'В среднем заказов у продавца, руб.',
  [LABELS_KEYS.EMPTY3]: 'Empty3',
  [LABELS_KEYS.EMPTY4]: 'Empty4',
  [LABELS_KEYS.EMPTY5]: 'Empty5',
};

const getDateDuration = (date) =>
  moment.duration(moment(date[1], DATE_FORMAT).diff(moment(date[0], DATE_FORMAT))).asDays() + 1;

const checkPeriod = ({ date, period }) => {
  const duration = getDateDuration(date);

  if (period === PERIOD_DAY) {
    return duration <= 30;
  }

  if (period === PERIOD_WEEK) {
    return duration > 30;
  }
};

const getAvailablePeriods = (date) => {
  return PERIODS.filter((period) => checkPeriod({ date, period }));
};

const transformData = (data = {}) => {
  const { all_data, ...other } = data;
  return {
    all_data: {
      plain_values:
        all_data?.plain_values?.map(({ data_type, ...other }) => ({
          ...other,
          data_type: data_type,
        })) || [],
    },
    ...other,
  };
};

const DynamicContext = React.createContext();
DynamicContext.displayName = 'Dynamic.Context';

const DynamicProvider = (props) => {
  const {
    filter: { date, period, warehouseType },
    setDayPeriod,
    setWeekPeriod,
    setMonthPeriod,
  } = useFilterReducer();
  const [type, setType] = useState(TYPES[0]);

  const { filter, isLoaded } = useFilterReducer();
  const { checkDynamicAvailability } = useUserTariffs();
  const { isDynamicAvailable } = checkDynamicAvailability(filter);
  const canLoadDynamic = isDynamicAvailable && isLoaded;

  const handlePeriod = (value) => {
    if (value === PERIOD_DAY) {
      setDayPeriod();
    }

    if (value === PERIOD_WEEK) {
      setWeekPeriod();
    }

    if (value === PERIOD_MONTH) {
      setMonthPeriod();
    }
  };

  const handleTypeClick = (value) => setType(value);

  let { data, isLoading, isFetching, error } = useMultiChartFilteredData({
    type: CHART_TYPE.DYNAMIC,
    extraParams: { period, warehouseType },
    enabled: canLoadDynamic && checkPeriod({ date, period }),
  });
  data = transformData(data);
  const dataChart = transformDynamicChartsData({
    data,
    keys: TYPES,
    nameKey: NAME_KEY,
  });

  const dynamicData = data?.all_data?.plain_values || [];
  const dataTable = {
    all_data: [...dynamicData],
    chart_dates: data?.chart_dates,
  };

  const value = {
    periods: PERIODS,
    periodsAvailable: getAvailablePeriods(date),
    periodActive: period,
    types: TYPES,
    typeActive: type,
    handlePeriod,
    handleTypeClick,
    labels: LABELS,
    dataTable,
    dataChart,
    isDataLoading: isLoading || isFetching,
    dataError: error,
  };

  return <DynamicContext.Provider value={value} {...props} />;
};

const useDynamic = () => {
  return React.useContext(DynamicContext);
};

export { DynamicProvider, useDynamic };
