import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const CodeVerification = ({ onChange, digits = 5, loading = false }) => {
  const [otp, setOtp] = useState(Array(digits).fill(''));

  useEffect(() => {
    if (otp.join('').length === digits) {
      onChange(otp.join(''));
    }
  }, [otp, onChange, digits]);

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/, '');
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text').slice(0, digits); // Limit to 5 characters
    const newOtp = Array.from(pastedData).map((char) => char.replace(/\D/, '').slice(0, 1));
    setOtp(newOtp);

    newOtp.forEach((digit, index) => {
      if (digit && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    });
  };

  return (
    <div className="flex space-x-2 relative" onPaste={handlePaste}>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-transparent border-green rounded-full animate-spin"></div>
        </div>
      )}
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-${index}`}
          type="text"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="w-12 h-12 text-center border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          maxLength="1"
        />
      ))}
    </div>
  );
};

export default CodeVerification;

CodeVerification.propTypes = {
  onChange: PropTypes.func.required,
  digits: PropTypes.number,
  loading: PropTypes.bool,
};
