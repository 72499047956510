import {
  PAGE_INNER_PRODUCT,
  PAGE_PRODUCT,
  PAGE_PRODUCT_END_COLORS,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_ORDERS_SALES,
  PAGE_PRODUCT_END_PROMOTIONS,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_STOCKS,
} from 'constants/pages';

const getOuterLinks = ({
  id,
  isOzonPlatform,
  isProductWarehousesAvailable,
  isProductSizesColorsAvailable,
  isProductListingAvailable,
  isProductSearchAvailable,
  // isAdvertisingFilterAvailable,
  isHaveSomeColorsAvailable,
  isHaveSomeSizesAvailable,
  computedTip,
}) => {
  return [
    {
      link: `${PAGE_PRODUCT}/${id}`,
      title: 'Заказы',
      single: true,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_STOCKS}`,
      title: 'Склады',
      isAvailable: isProductWarehousesAvailable,
      hidden: isOzonPlatform,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_SIZES}`,
      title: 'Размеры',
      tip: computedTip(),
      isAvailable: isProductSizesColorsAvailable && isHaveSomeSizesAvailable(),
      single: isOzonPlatform,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_COLORS}`,
      title: 'Цвета',
      tip: computedTip(),
      isAvailable: isProductSizesColorsAvailable && isHaveSomeColorsAvailable(),
      single: isOzonPlatform,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_PROMOTIONS}`,
      title: 'Акции',
      tip: computedTip(),
      isAvailable: true,
      single: isOzonPlatform,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_LISTING}`,
      title: 'Позиции в категориях',
      isAvailable: isProductListingAvailable,
      first: !isOzonPlatform,
      single: isOzonPlatform,
    },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_SEARCH}`,
      title: 'Поисковые запросы',
      isAvailable: isProductSearchAvailable,
      hidden: isOzonPlatform,
    },
    /*{
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_ADV}`,
      title: 'Реклама',
      isAvailable: isAdvertisingFilterAvailable,
      single: true,
      hidden: isOzonPlatform,
    },*/
    // {
    //   link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_CHANGES}`,
    //   title: 'Изменения',
    //   single: true,
    // },
    {
      link: `${PAGE_PRODUCT}/${id}/${PAGE_PRODUCT_END_SIMILAR}`,
      title: 'Похожие товары',
      single: true,
    },
  ];
};

const getInnerLinks = ({
  id,
  barcode,
  isProductListingAvailable,
  isOzonPlatform,
  isProductSearchAvailable,
  isInnerSizesAvailable,
}) => {
  return [
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}`,
      title: 'По дням',
      single: true,
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_ORDERS_SALES}`,
      title: 'Заказы. Продажи. Возвраты',
      single: true,
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_STOCKS}`,
      single: true,
      title: 'Склады',
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_SIZES}`,
      single: true,
      isAvailable: isInnerSizesAvailable,
      useTooltip: false,
      title: 'Размеры',
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_LISTING}`,
      title: 'Позиции в категориях',
      isAvailable: isProductListingAvailable,
      single: true,
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_SEARCH}`,
      title: 'Поисковые запросы',
      isAvailable: isProductSearchAvailable,
      hidden: isOzonPlatform,
      single: true,
    },
    {
      link: `${PAGE_INNER_PRODUCT}/${id}/${barcode}/${PAGE_PRODUCT_END_SIMILAR}`,
      single: true,
      title: 'Похожие товары',
    },
  ];
};

export const getLinks = ({ isInner, ...other }) => {
  if (isInner) {
    return getInnerLinks(other);
  }
  return getOuterLinks(other);
};
