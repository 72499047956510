import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Divider from 'components/Divider';
import { NAME_KEY, TYPE_ABSOLUTE, TYPE_PERCENT } from 'constants/chart';
import { BLUE, COLORS_PROPS, GRAY_DARK, GREEN, ORANGE, PINK, PURPLE } from 'constants/colors';
import { COLUMNS } from 'constants/table';
import { useUI } from 'context/ui.context';
import { deleteStateKey } from 'helpers/utils';
import useLocalStorage from 'hooks/useLocalStorage';
import CustomizedLegend, { getKey } from './CustomizedLegend';
import CustomizedTooltip from './CustomizedTooltip';
import { formatYAxis, getAxisYDomain, getStrokeDasharray } from './utils';

const STEPS = 9;
const YAxisIdPercentKey = 'LEFT';

const ROW_HEIGHT = 24;
const GRID_HEIGHT = 200;
const LEGEND_MARGIN = 20;

const MultiChart = memo((props) => {
  const {
    data,
    scatterData,
    isLoading = true,
    isScatterDataLoading = true,
    error,
    scatterError,
    type,
    labels,
    initialLabels,
    yAxisAngle = 0,
    controls,
    formatXAxis,
    formatTooltipLabel,
    colors = [PURPLE, ORANGE, PINK, GREEN, BLUE],
    labelsPostfix = {},
    title,
    subtitle,
    columns = 5,
    dynamicLabels,
    shownDynamicLabels,
    removeShownLabel,
    dynamicColors = {},
    scatterColors,
    reversed = [],
    excludeLabelsFromStorage,
    chartKey,
  } = props || {};

  const [userActiveLabels, setUserActiveLabels] = useLocalStorage(`MULTI-CHART-${chartKey}`);
  const [hoveredKey, setHoveredKey] = useState();

  const initialActive = useMemo(() => {
    const validUserActiveLabels =
      Array.isArray(userActiveLabels) && userActiveLabels.length > 0 ? userActiveLabels : null;

    const validInitialLabels =
      Array.isArray(initialLabels) && initialLabels.length > 0
        ? initialLabels
        : Object.keys(labels).slice(0, 3);

    return validUserActiveLabels || validInitialLabels;
  }, [labels, initialLabels, userActiveLabels]);

  const [active, setActive] = useState(initialActive);
  const [orientations, setOrientations] = useState({});

  // const labelsForLS = excludeLabelsFromStorage
  //   ? active.filter((id) => !excludeLabelsFromStorage.includes(id))
  //   : active;
  const labelsForLS = useMemo(() => {
    if (!excludeLabelsFromStorage) {
      return active;
    }
    return [...active].filter((id) => !excludeLabelsFromStorage.includes(id));
  }, [excludeLabelsFromStorage, active]);

  const { isMobile } = useUI();

  const getYAxisOrientation = (prev, result = {}) => {
    let countLeft = 0;
    let countRight = 0;

    const count = (obj) => {
      Object.keys(obj).forEach((name) => {
        const orientation = obj[name];

        if (orientation === 'left') ++countLeft;
        if (orientation === 'right') ++countRight;
      });
    };

    count(prev);
    count(result);

    if (countLeft < 3) {
      return 'left';
    }
    return countLeft > countRight ? 'right' : 'left';
  };

  const getOrientations = useCallback(
    (prev) => {
      const result = {};
      active.forEach((name) => {
        result[name] = prev[name] || getYAxisOrientation(prev);
      });

      return result;
    },
    [active],
  );

  const addActive = (key) => {
    setActive((prev) => {
      const activeNotDynamicLabels = dynamicLabels
        ? prev.filter((key) => !dynamicLabels[key])
        : prev;
      return [...activeNotDynamicLabels, key, ...Object.keys(shownDynamicLabels ?? {})];
    });

    setOrientations((prev) => ({
      ...prev,
      [key]: getYAxisOrientation(prev),
    }));
  };

  const removeActive = (key) => {
    setActive((prev) => prev.filter((value) => value !== key));
    setOrientations((prev) => deleteStateKey(prev, key));
  };

  const handleActive = (e) => {
    const key = e.target.id;
    const index = active.indexOf(key);

    if (index < 0) {
      addActive(key);
    } else {
      removeActive(key);
      removeShownLabel && removeShownLabel(key);
    }
  };

  const labelsLength = Object.keys(labels).length + Object.keys(shownDynamicLabels ?? {}).length;
  const labelsRows = Math.ceil(labelsLength / columns);
  const labelsHeight = labelsRows * ROW_HEIGHT + LEGEND_MARGIN;
  const chartHeight = labelsHeight + GRID_HEIGHT + 40;

  const getTooltipPosition = () => {
    let y = active.length ? labelsHeight - 44 : 0;
    if (isMobile) y = labelsHeight - 44;
    return { y };
  };

  useEffect(() => {
    setActive((prev) => {
      const activeNotHiddenLabels = dynamicLabels
        ? prev.filter((key) => !dynamicLabels[key])
        : prev;
      return [...activeNotHiddenLabels, ...Object.keys(shownDynamicLabels ?? {})];
    });
  }, [shownDynamicLabels, dynamicLabels]);

  const isDataExist = data[type] ? data[type].length > 0 : false;

  useEffect(() => {
    setOrientations((prev) => getOrientations(prev));
  }, [active, getOrientations]);

  useEffect(() => {
    setUserActiveLabels(labelsForLS);
  }, [labelsForLS, setUserActiveLabels]);

  const showCharts = !isLoading && !error;
  const showScattered = !isLoading && !isScatterDataLoading && !scatterError && scatterData;
  return (
    <div className="bg-white border border-gray-200 rounded-2xl pt-4 pl-4 z-30 relative overflow-x-auto lg:overflow-x-visible">
      {title && <h2>{title}</h2>}

      {subtitle && <h3 className="mt-1 text-xs font-normal text-gray-500">{subtitle}</h3>}

      {(title || subtitle) && (
        <div className="mb-4 mr-5">
          <Divider />
        </div>
      )}

      <div style={{ width: '100%', height: chartHeight }}>
        {isLoading && (
          <div className="absolute inset-x-5 bottom-0 top-[90px] multi-chart-loading" />
        )}

        <ResponsiveContainer width={isMobile ? 900 : '100%'} height={chartHeight} minWidth={'100%'}>
          <ComposedChart
            data={data[type]}
            margin={{
              right: 20,
              left: 0,
              bottom: 10,
              top: labelsHeight,
            }}
          >
            {isDataExist && (
              <Legend
                verticalAlign="top"
                height={0}
                wrapperStyle={{ marginTop: `-${labelsHeight}px` }}
                content={
                  <CustomizedLegend
                    data={data}
                    type={type}
                    active={active}
                    onChange={handleActive}
                    isError={!!error}
                    labels={labels}
                    shownDynamicLabels={shownDynamicLabels}
                    labelsPostfix={labelsPostfix}
                    controls={controls}
                    columns={columns}
                    colors={colors}
                    dynamicColors={dynamicColors}
                    setHoveredKey={setHoveredKey}
                  />
                }
              />
            )}

            {showCharts && <CartesianGrid stroke="#F0F0F0" height={GRID_HEIGHT} />}
            {showCharts && (
              <XAxis
                dataKey={NAME_KEY}
                tick={{ fontSize: '10px', fill: GRAY_DARK }}
                tickLine={false}
                stroke="#F0F0F0"
                interval={data[type]?.length > 15 ? 1 : 0}
                tickFormatter={formatXAxis}
                angle={yAxisAngle}
                tickMargin={10}
              />
            )}
            {showCharts && (
              <Tooltip
                position={getTooltipPosition()}
                content={
                  <CustomizedTooltip
                    type={type}
                    data={data}
                    nameKey={NAME_KEY}
                    isLoading={isLoading}
                    labels={labels}
                    dynamicLabels={dynamicLabels}
                    formatLabel={formatTooltipLabel}
                    activeLabels={active}
                    scatterData={scatterData?.data}
                    colors={colors}
                    dynamicColors={dynamicColors}
                  />
                }
              />
            )}
            {showCharts &&
              Object.keys(labels).map((name, index) => {
                const dataKey = getKey({ name, type });
                const yAxisId = type === TYPE_ABSOLUTE ? dataKey : YAxisIdPercentKey;
                const activeIndex = active.indexOf(name);
                const hide = activeIndex < 0;
                const color = colors[index];
                const strokeDasharray = getStrokeDasharray({ index, columns });

                const [bottom, top] = getAxisYDomain({
                  key: dataKey,
                  data: data[type],
                  steps: STEPS - 1,
                });

                if (hide) {
                  return null;
                }

                const items = [];

                if (name === COLUMNS.ORDERS_RUB_ || name === COLUMNS.SUM_ORDERS_INNER) {
                  items.push(
                    <defs>
                      <linearGradient id="colorOrdersRub" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={color} stopOpacity={0} />
                      </linearGradient>
                    </defs>,
                  );
                  items.push(
                    <Area
                      type="monotone"
                      dataKey={dataKey}
                      activeDot={!hide}
                      stroke={hide ? 'transparent' : colors[index]}
                      yAxisId={yAxisId}
                      connectNulls
                      fillOpacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                      opacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                      fill={hide ? 'transparent' : 'url(#colorOrdersRub)'}
                    />,
                  );
                } else {
                  items.push(
                    <Line
                      type="monotone"
                      dataKey={dataKey}
                      activeDot={!hide}
                      dot={!hide}
                      stroke={hide ? 'transparent' : color}
                      strokeWidth={hide ? 0 : 1}
                      yAxisId={yAxisId}
                      connectNulls
                      fillOpacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                      opacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                      strokeDasharray={strokeDasharray}
                    />,
                  );
                }

                if (type === TYPE_ABSOLUTE) {
                  items.push(
                    <YAxis
                      key={dataKey}
                      yAxisId={dataKey}
                      domain={[bottom, top]}
                      orientation={orientations[name]}
                      width={38}
                      tickLine={false}
                      axisLine={false}
                      tickFormatter={formatYAxis}
                      tick={{
                        fill: color,
                        fontSize: '10px',
                        opacity: (hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2,
                      }}
                      hide={hide}
                      interval={0}
                      tickCount={STEPS}
                      reversed={reversed.includes(name)}
                    />,
                  );
                }

                return items;
              })}
            {showCharts && type === TYPE_PERCENT && (
              <YAxis
                key={YAxisIdPercentKey}
                yAxisId={YAxisIdPercentKey}
                domain={[0, 'auto']}
                orientation="left"
                width={30}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatYAxis}
                tick={{
                  fill: GRAY_DARK,
                  fontSize: '10px',
                  opacity: (hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2,
                }}
                interval={0}
                tickCount={STEPS}
                reversed={reversed.includes(name)}
              />
            )}
            {showCharts &&
              Object.keys(dynamicColors).map((name, index) => {
                const key = getKey({ name, type });
                const activeIndex = Object.keys(labels).length + index;
                const color = dynamicColors[name];
                const strokeDasharray = getStrokeDasharray({ index: activeIndex, columns });

                const [bottom, top] = getAxisYDomain({
                  key,
                  data: data[type],
                  steps: STEPS - 1,
                });

                let items = [
                  <Line
                    type="monotone"
                    dataKey={key}
                    key={key}
                    activeDot={true}
                    dot={true}
                    stroke={color}
                    strokeWidth={1}
                    yAxisId={key}
                    connectNulls
                    fillOpacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                    opacity={(hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2}
                    strokeDasharray={strokeDasharray}
                  />,
                ];

                if (type === TYPE_ABSOLUTE) {
                  items.push(
                    <YAxis
                      key={key}
                      yAxisId={key}
                      domain={[bottom, top]}
                      orientation={orientations[name]}
                      width={54}
                      tickLine={false}
                      axisLine={false}
                      tickFormatter={formatYAxis}
                      tick={{
                        fill: color,
                        fontSize: '10px',
                        opacity: (hoveredKey && hoveredKey === name) || !hoveredKey ? 1 : 0.2,
                      }}
                      interval={0}
                      tickCount={STEPS}
                      reversed={reversed.includes(name)}
                    />,
                  );
                }

                return items;
              })}

            {showScattered && <YAxis domain={[0, 10]} hide={true} />}
            {showScattered && <Tooltip />}
            {showScattered &&
              scatterData.keys.map((key, i) => (
                <Scatter
                  key={key}
                  name={key}
                  dataKey={key}
                  fill={scatterColors[i] || scatterColors[0]}
                />
              ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

MultiChart.displayName = 'MultiChart';

MultiChart.propTypes = {
  error: PropTypes.any,
  scatterError: PropTypes.any,
  data: PropTypes.object.isRequired,
  scatterData: PropTypes.shape({
    keys: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
  }),
  isLoading: PropTypes.bool,
  isScatterDataLoading: PropTypes.bool,
  type: PropTypes.oneOf([TYPE_ABSOLUTE, TYPE_PERCENT]),
  initialLabels: PropTypes.array,
  yAxisAngle: PropTypes.number,
  controls: PropTypes.element,
  formatXAxis: PropTypes.func,
  formatTooltipLabel: PropTypes.func,
  onDataReady: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.oneOf(COLORS_PROPS)),
  scatterColors: PropTypes.arrayOf(PropTypes.oneOf(COLORS_PROPS)),
  dynamicColors: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  columns: PropTypes.number,
  labels: PropTypes.object.isRequired,
  labelsPostfix: PropTypes.object,
  dynamicLabels: PropTypes.object,
  shownDynamicLabels: PropTypes.object,
  removeShownLabel: PropTypes.func,
  reversed: PropTypes.array,
  //лэйблы, которые не надо запоминать в LS для юзера
  excludeLabelsFromStorage: PropTypes.array,
  chartKey: PropTypes.string.isRequired,
};
export default MultiChart;
