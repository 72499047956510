import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ResendTimer = ({ initialTime = 60, onResend, disabled }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startTimer = () => {
    setIsTimerRunning(true);
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsTimerRunning(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return timer; // Return the timer ID for cleanup
  };

  useEffect(() => {
    // Reset timer on component mount
    setTimeLeft(initialTime);

    const timer = startTimer();

    return () => clearInterval(timer); // Clean up the timer on unmount
  }, [initialTime]); // Empty dependency array to run only on mount

  const handleResend = () => {
    if (disabled || isTimerRunning) return;
    onResend();
    setTimeLeft(initialTime); // Reset the timer when resending
    startTimer();
  };

  return (
    <button
      onClick={handleResend}
      disabled={disabled || timeLeft > 0}
      className={timeLeft > 0 ? 'text-gray-300' : 'text-green'}
    >
      {timeLeft > 0 ? `Выслать код повторно (через ${timeLeft} сек)` : 'Выслать код повторно'}
    </button>
  );
};

export default ResendTimer;

ResendTimer.propTypes = {
  onResend: PropTypes.func.required,
  initialTime: PropTypes.number,
  disabled: PropTypes.bool,
};
