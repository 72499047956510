import { useInfiniteQuery, useQuery } from 'react-query';

import { F } from 'constants/filter';
import { STALE_TIME } from 'constants/query';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import { isTableWithNewAdvertising } from 'helpers/filter';
import apiClient from 'services/baseClient';

export const FILTER_BACK_PARAMS = [
  'platform',
  'product',
  'date',
  'brand',
  'seller',
  'subject',
  'promo',
];

const TYPE_WITH_COLUMN_PARAM = [TABLE_TYPE.BRANDS, TABLE_TYPE.SELLERS];
const ifHasColumnParam = (type) => {
  return TYPE_WITH_COLUMN_PARAM.includes(type);
};

const TYPE_WITH_CATEGORY_PARAM = [TABLE_TYPE.PRICE_SEGMENT];
const ifHasTreeCategoryParam = (type) => {
  return !TYPE_WITH_CATEGORY_PARAM.includes(type);
};

const TYPE_WITH_DEPTH_PARAM = [TABLE_TYPE.CATEGORIES, TABLE_TYPE.CATEGORIES_GROWTH];
const ifHasDepthParam = (type) => {
  return TYPE_WITH_DEPTH_PARAM.includes(type);
};

export const getFilterParamsForBack = ({
  filter,
  setDateInitial,
  dateInitial,
  type,
  isOzonPlatform,
}) => {
  const filterForBack = Object.keys(filter)
    .filter((key) => FILTER_BACK_PARAMS.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: filter[key] });
    }, {});

  if (ifHasTreeCategoryParam(type)) {
    filterForBack.tree_category = [...filter[F.CATEGORY]];
  } else {
    filterForBack.category = filter[F.CATEGORY];
  }

  if (isOzonPlatform) {
    filterForBack.warehouse = filter[F.WAREHOUSE];
  }

  filterForBack.platform = [filterForBack.platform];

  filterForBack.date = setDateInitial && dateInitial ? dateInitial : filterForBack.date;

  filterForBack.product = filter[F.PRODUCT];

  return filterForBack;
};

const useNameOption = (type) => {
  const { isFilterTextOnBack } = useTable();
  const { filterText } = useFilterReducer();

  if (!filterText) return null;

  const keys = Object.keys(filterText);

  for (let i = 0; i < keys.length; i++) {
    if (isFilterTextOnBack({ type, key: keys[i] })) {
      return { [keys[i]]: [filterText[keys[i]]] };
    }
  }

  return null;
};

const useRestrictsOption = (type) => {
  const { isTableRestrictedOnBack } = useTable();
  const { filterRange } = useFilterReducer();

  if (!filterRange) return {};

  const result = {};

  const keys = Object.keys(filterRange);

  for (let i = 0; i < keys.length; i++) {
    if (isTableRestrictedOnBack(type)) {
      result[keys[i]] = filterRange[keys[i]];
    }
  }

  return result;
};

const action = {
  [TABLE_TYPE.CATEGORIES]: (props) => apiClient.getSalesCategories(props),
  [TABLE_TYPE.WAREHOUSES]: (props) => apiClient.getSalesWarehouses(props),
  [TABLE_TYPE.BRANDS]: (props) => apiClient.getSalesBrands(props),
  [TABLE_TYPE.PRODUCTS]: (props) => apiClient.getSalesProducts(props),
  [TABLE_TYPE.SUBJECTS]: (props) => apiClient.getSalesSubjects(props),
  [TABLE_TYPE.SALES_BY_DAY]: (props) => apiClient.getSales(props),
  [TABLE_TYPE.SELLERS]: (props) => apiClient.getSalesSellers(props),
  [TABLE_TYPE.CATEGORIES_GROWTH]: (props) => apiClient.getSalesCategoriesGrowth(props),
  [TABLE_TYPE.BRANDS_GROWTH]: (props) => apiClient.getSalesBrandsGrowth(props),
  [TABLE_TYPE.PRODUCTS_GROWTH]: (props) => apiClient.getSalesProductsGrowth(props),
  [TABLE_TYPE.SELLERS_GROWTH]: (props) => apiClient.getSellersGrowth(props),
  [TABLE_TYPE.SUBJECTS_GROWTH]: (props) => apiClient.getSubjectsGrowth(props),
  [TABLE_TYPE.COMPARISON]: (props) => apiClient.getCompare(props),
  [TABLE_TYPE.SEASONALITY]: (props) => apiClient.getSeasonality(props),

  // seo
  [TABLE_TYPE.SEO_SEARCH]: (props) => {
    return apiClient.getSeoSearch(props);
  },
};

const useFetchFilteredDataOptions = ({
  type,
  enabled,
  setDateInitial,
  depth,
  category,
  promo,
  textRestrictsSortInitial,
  extraParams,
}) => {
  let { filter, isLoaded, dateInitial, isOzonPlatform } = useFilterReducer();
  const { isTableSortedOnBack } = useTable();
  const { isNew, hasAdvertising, sort, order } = filter;

  // если надо сделать запрос для конкретной категории,
  // независимо от текущего состояния фильтра
  // (для загрузки детей в категории по клику на раскрыть категорию в таблице категорий)
  if (category) {
    filter = {
      ...filter,
      [F.CATEGORY]: Array.isArray(category) ? category : [category],
    };
  }

  if (promo) {
    filter = {
      ...filter,
      [F.PROMO_CATEGORY]: Array.isArray(promo) ? promo : [promo],
    };
  }

  const filterForBack = getFilterParamsForBack({
    filter,
    setDateInitial,
    dateInitial,
    type,
    isOzonPlatform,
  });

  const options = {
    filter: filterForBack,
  };

  if (isTableWithNewAdvertising(type)) {
    options.isNew = isNew;
    options.hasAdvertising = hasAdvertising;
  }

  if (ifHasDepthParam(type) && depth) {
    options.depth = depth;
  }

  if (ifHasColumnParam(type)) {
    options.isColumn =
      filter.brand.length > 0 || filter.seller.length > 0 || filter.category.length > 0;
  }
  if (isTableSortedOnBack(type) && !textRestrictsSortInitial) {
    options.sort = sort;
    options.order = order;
  } else {
    options.sortDefault = true;
  }

  // const name = useNameOption(type);
  // console.log(name, textRestrictsSortInitial)
  // if (name && !textRestrictsSortInitial) {
  //   options.name = name;
  // } else {
  //   options.name = null;
  // }
  const restricts = useRestrictsOption(type);
  if (restricts && !textRestrictsSortInitial) {
    options.restricts = restricts;
  } else {
    options.restricts = {};
  }

  const textFilter = useNameOption(type);
  if (textFilter && !textRestrictsSortInitial) {
    Object.entries(textFilter).forEach(([key, value]) => (restricts[key] = value));
  }

  if (!isOzonPlatform) {
    options.warehouseType = filter[F.WAREHOUSE_TYPE] || '';
  }

  if (type === TABLE_TYPE.SEASONALITY) {
    options.granularity = filter.period || 'week';
  }

  const queryKey = [type, options, extraParams];

  return {
    actionOption: options,
    queryOptions: {
      queryKey,
      enabled: enabled && isLoaded,
      refetchOnmount: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME,
      retry: 3,
      keepPreviousData: true,
    },
  };
};

export const useFilterForExportData = ({ type }) => {
  const { actionOption } = useFetchFilteredDataOptions({
    type,
  });

  return actionOption;
};

export const useFetchFilteredData = ({
  type,
  enabled = true,
  onSuccess,
  extraParams,
  setDateInitial = false,
  depth = null,
  category = null,
  // для запроосов, которые не должны зависеть от фильтра range/name/sort
  // (например, для плашек из TableCardList)
  textRestrictsSortInitial = false,
}) => {
  const { actionOption, queryOptions } = useFetchFilteredDataOptions({
    type,
    enabled,
    extraParams,
    setDateInitial,
    depth,
    category,
    textRestrictsSortInitial,
  });

  return useQuery({
    ...queryOptions,
    queryFn: action[type] ? () => action[type](actionOption) : null,
    onSuccess,
  });
};

export const useFetchFilteredDataPaginated = ({
  type,
  enabled = true,
  onSuccess,
  extraParams,
  setDateInitial = false,
  depth = null,
  category = null,
  textRestrictsSortInitial = false,
}) => {
  const { actionOption, queryOptions } = useFetchFilteredDataOptions({
    type,
    enabled,
    extraParams,
    setDateInitial,
    depth,
    category,
    textRestrictsSortInitial,
  });

  const queryFn = ({ pageParam = 1 }) => {
    return action[type] && action[type]({ ...actionOption, ...extraParams, page: pageParam });
  };

  return useInfiniteQuery({
    ...queryOptions,
    queryFn,
    getNextPageParam: (_, pages) => pages.length + 1,
    onSuccess,
  });
};

export const actionTypes = Object.keys(action);
