import cn from 'classnames';
import PropTypes from 'prop-types';

import { CalendarIcon } from 'assets/icons/CalendarIcon';
import TimerIconThin from 'assets/icons/TimerIconThin';
import { List } from './';

export const TariffItemShape = PropTypes.shape({
  oldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  newPrice: PropTypes.number,
  monthlyPrice: PropTypes.number,
  textPrice: PropTypes.string,
  title: PropTypes.string,
  dataLengthText: PropTypes.string,
  sellers: PropTypes.bool,
  sizes: PropTypes.bool,
  colors: PropTypes.bool,
  warehouses: PropTypes.bool,
  newItems: PropTypes.bool,
  trends: PropTypes.bool,
  priceSegments: PropTypes.bool,
  categoryPositions: PropTypes.bool,
  searchQueries: PropTypes.bool,
  priceChange: PropTypes.bool,
  ratingsAndReviews: PropTypes.bool,
  extension: PropTypes.bool,
  advertisement: PropTypes.bool,
  buttonText: PropTypes.string,
  modalButtonText: PropTypes.string,
  fileExport: PropTypes.bool,
  content: PropTypes.bool,
  recommend: PropTypes.bool,
  dataPeriod: PropTypes.string,
  dataWarning: PropTypes.string,
}).isRequired;

export const getFormattedPrice = (price) => price.toLocaleString();

export const TariffColumn = ({ item, highlight, modalView, onSubmit }) => {
  const recommend = item.recommend;
  return (
    <div
      className={cn(
        'w-full lg:w-1/3 flex-row text-xs px-2 lg:px-10 py-8 rounded-xl bg-white  shadow-[0_4px_80px_rgba(39,150,83,0.12)]',
      )}
    >
      {recommend ? (
        <div className="text-red-500 font-normal leading-5 tracking-widest mb-1">{recommend}</div>
      ) : (
        <div className="h-6" />
      )}

      <h2 className="flex text-3xl font-bold">
        {item.title}
        <div className="border-dashed border-gray-300 border-b flex-1 mx-2 h-7" />
        {item.oldPrice ? (
          <div className="text-gray-300 font-normal text-2xl line-through mr-1">
            {getFormattedPrice(item.oldPrice)}
          </div>
        ) : (
          ''
        )}
        <div className="text-green font-bold text-2xl">
          {typeof item.newPrice === 'string'
            ? item.newPrice
            : getFormattedPrice(item.newPrice) + ' ₽'}{' '}
        </div>
      </h2>
      <div className="text-right text-xs h-3 text-gray-500">
        {item.monthlyPrice && <>{item.monthlyPrice} ₽/МЕС</>}
      </div>

      {item.dataLengthText && (
        <div className="my-6 py-3 bg-dotted-big">
          <div className="flex space-x-3 items-center justify-center">
            <CalendarIcon className={'text-green -mt-1'} />
            <div className="flex space-x-1 text-base  leading-6">
              <span className="block text-[#6E6E6E]">Окно выбора дат</span>
              <span className="block text-black">{item.dataLengthText}</span>
            </div>
          </div>
        </div>
      )}

      {item.dataPeriod && (
        <div className="my-6 py-3 bg-dotted-big">
          <div className="flex space-x-3 items-center justify-center">
            <TimerIconThin className={'text-green'} />
            <div className="flex space-x-1 text-base leading-6">
              <span className="block text-[#6E6E6E]">Данные с</span>
              <span className="block text-black">2022 года</span>
            </div>
          </div>
        </div>
      )}

      <List item={item} />

      <button
        className={cn(
          'mt-5 ml-3y-2 leading-normal primary-button w-full p-3 rounded-full text-xl flex items-center justify-center',
          {
            'text-white bg-gradient-to-r from-slate-800 to-blue-900 hover:from-slate-900  hover:to-blue-900':
              highlight,
            'border border-green text-green hover:text-white hover:bg-green': !highlight,
          },
        )}
        onClick={onSubmit}
      >
        {modalView ? (item.modalButtonText ?? item.buttonText) : item.buttonText}
        {/*{getFormattedPrice(item.newPrice)}*/}
      </button>
    </div>
  );
};
TariffColumn.propTypes = {
  item: TariffItemShape,
  disabledButton: PropTypes.bool,
  highlight: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  modalView: PropTypes.bool,
};
