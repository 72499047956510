import PropTypes from 'prop-types';

const opacity = '0.5';
const CompareDiagram = ({ groupA, groupB, cross: intersection }) => {
  const totalWidth = 400;
  const totalHeight = 350;

  const maxGroupSize = Math.max(groupA, groupB);
  const maxRadius = totalWidth * 0.3;

  const radiusA = (groupA / maxGroupSize) * maxRadius;
  const radiusB = (groupB / maxGroupSize) * maxRadius;

  const intersectionOffset = (intersection / Math.max(groupA, groupB)) * (radiusA - radiusB);

  const circleAX = totalWidth * 0.3;
  const circleBX = totalWidth * 0.7;

  const colors = {
    A: { bg: '#DAF5E2', border: '#219653' },
    B: { bg: '#f0f09e', border: '#f0e397' },
    AB: { bg: '#E5F3C0', border: '#D1EABA' },
  };

  return (
    <div
      style={{
        height: `${totalHeight}px`,
        margin: '0 auto',
      }}
    >
      <div>
        <svg width={circleBX - intersectionOffset + radiusB} height={totalHeight}>
          <circle
            cx={circleAX}
            cy={totalHeight / 2}
            r={radiusA}
            fill={colors.A.bg}
            stroke={colors.A.border}
            fillOpacity={opacity}
          />
          <text
            x={circleAX}
            y={totalHeight / 2}
            fill="black"
            fontSize="14"
            textAnchor="middle"
            alignmentBaseline="central"
          >
            A
          </text>

          <circle
            cx={circleBX - intersectionOffset}
            cy={totalHeight / 2}
            r={radiusB}
            fill={colors.B.bg}
            stroke={colors.B.border}
            fillOpacity={opacity}
          />
          <text
            x={circleBX - intersectionOffset}
            y={totalHeight / 2}
            fill="black"
            fontSize="14"
            textAnchor="middle"
            alignmentBaseline="central"
          >
            B
          </text>
        </svg>
      </div>
      <div className={'flex items-center justify-center gap-8'}>
        <Legend label={'Группа A'} color={colors.A} />
        {intersection > 0 && <Legend label={'Группа A/B'} color={colors.AB} />}
        <Legend label={'Группа B'} color={colors.B} />
      </div>
    </div>
  );
};

CompareDiagram.propTypes = {
  groupA: PropTypes.number,
  groupB: PropTypes.number,
  cross: PropTypes.number,
};

export default CompareDiagram;

const Legend = ({ label, color }) => {
  return (
    <span className={'flex items-center gap-4'}>
      <div
        style={{
          background: color.bg,
          width: 16,
          height: 16,
          borderRadius: '50%',
          opacity,
        }}
      ></div>
      {label}
    </span>
  );
};
Legend.propTypes = {
  label: PropTypes.string,
  color: PropTypes.object,
};
