import Tree from 'rc-tree';
import React, { useState } from 'react';

import 'assets/styles/components/tree.css';
import 'rc-tree/assets/index.css';

import LoadingSpin from 'components/LoadingSpin';
import { useModalFilter } from 'context/filter.modal.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import {
  getFlatList,
  getParentKey,
  removeDuplicates,
  removeKeyFromCategories,
  wrapSearchResults,
} from 'helpers/utils';
import { useDebounce } from 'hooks';
import { useSearchCategories } from 'hooks/useSearch';
import { SearchInput } from '../components';

export const Categories = () => {
  const { filter, chips } = useFilterReducer();
  const { isMobile } = useUI();

  const categoryKey = 'category';
  const [expandedKeys, setExpandedKeys] = useState(filter?.[categoryKey] || []);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [search, setSearch] = useState('');

  const { data, isLoading } = useSearchCategories(true);

  const treeData = data?.[0]?.children || [];

  const {
    state: { localFilter, touched, localChips },
    updateCategoriesList,
  } = useModalFilter();
  const debouncedSearch = useDebounce(search, 500);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const addExpandedKey = (prev, key) => (prev.indexOf(key) < 0 ? [...prev, key] : prev);

  const removeExpandedKey = (prev, key) => {
    const index = prev.indexOf(key);
    if (index >= 0) {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    }

    return prev;
  };

  const onCategoryCheck = (checkedKeys, { node: { key, title, titleClean, checked } }) => {
    let result = [];

    if (!checked) {
      result = [...(localChips?.[categoryKey] ?? []), { key, title: titleClean || title }];
      setExpandedKeys((prev) => addExpandedKey(prev, key));
    } else {
      result = removeKeyFromCategories(localChips?.[categoryKey] ?? [], key);
      setExpandedKeys((prev) => removeExpandedKey(prev, key));
    }

    updateCategoriesList(removeDuplicates(result) || []);
  };

  const onChange = (e) => {
    setAutoExpandParent(true);
    setSearch(e.target.value);
  };

  const selectedKeysFromFilter = localFilter?.[categoryKey] ?? []; // All nodes from localChips at localFilter;
  const selectedKeysFromChips = chips?.[categoryKey]?.map((item) => item.key) || []; // All keys from chips;

  const resultingCheckedKeys = [
    ...selectedKeysFromFilter, // we get selected keys from filter
    ...(touched[categoryKey] ? [] : selectedKeysFromChips), // we add keys from chips, if category wasn't touched;
  ];

  let resultingExpandedKeys = expandedKeys;
  let resultingTreeData = treeData;
  let message = '';

  if (debouncedSearch.length >= 3) {
    // if we've debounced search - transform treeData and set list of expanded keys;
    const flatList = getFlatList(treeData);

    const expandedWithSearch = flatList
      .map((item) => {
        return item.title.toLowerCase().indexOf(debouncedSearch.toLowerCase()) > -1
          ? getParentKey(item.key, treeData)
          : null;
      })
      .filter(Boolean);

    resultingExpandedKeys = [...expandedKeys, ...expandedWithSearch];

    resultingTreeData = wrapSearchResults(treeData, debouncedSearch);

    if (!resultingTreeData.length) {
      message = 'По вашему запросу нет данных. Попробуйте использовать другие слова для поиска';
    }
  }

  return (
    <React.Fragment>
      <SearchInput
        value={search}
        onChange={onChange}
        placeholder={'Поиск по категориям'}
        isLoading={isLoading}
      />
      <div
        className="
        flex-1
        sm:h-full
        "
      >
        {isLoading ? (
          <LoadingSpin isVisible={true} />
        ) : (
          <div
            className="
              overflow-y-auto
              sm:h-full md:max-h-[400px]
            "
          >
            {message}
            <Tree
              checkable
              selectable={false}
              onExpand={onExpand}
              autoExpandParent={autoExpandParent}
              expandedKeys={resultingExpandedKeys}
              treeData={resultingTreeData}
              checkedKeys={resultingCheckedKeys}
              onCheck={onCategoryCheck}
              showIcon={false}
              rootClassName={isMobile ? 'tree_mobile' : ''}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
