import cn from 'classnames';
import PropTypes from 'prop-types';

const ButtonLabel = ({
  isFirstInList,
  onClick,
  label,
  active = false,
  disabled = false,
  className,
}) => {
  return (
    <button
      className={cn(className, 'text-xs py-1 px-2 rounded leading-none', {
        'rounded-l-none': isFirstInList === false,
        'rounded-r-none': isFirstInList,

        ['text-gray-400 bg-gray-200 cursor-not-allowed']: disabled,
        ['text-green bg-green-light']: active,
        ['text-gray-400 hover:text-green']: !active && !disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

ButtonLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  disableActiveTitle: PropTypes.bool,
  isFirstInList: PropTypes.bool,
  className: PropTypes.string,
};
export default ButtonLabel;
