import PropTypes from 'prop-types';

import ButtonLabel from 'components/ButtonLabel';
import { PERIOD_MONTH, PERIOD_WEEK } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { isSeasonPage } from 'utils/pages';

export const TABLE_HEADER_AVAILABLE_PERIODS = [PERIOD_WEEK, PERIOD_MONTH];

const TableHeaderPeriodControls = ({ layout }) => {
  const {
    filter: { period },
    setWeekPeriod,
    setMonthPeriod,
  } = useFilterReducer();

  const TEXTS = isSeasonPage() ? ['По неделям', 'По месяцам'] : ['Неделя', 'Месяц'];

  const isLayoutGroup = layout === 'group';
  const buttons = TABLE_HEADER_AVAILABLE_PERIODS.map((key, index) => (
    <ButtonLabel
      key={key}
      onClick={key === PERIOD_WEEK ? setWeekPeriod : key === PERIOD_MONTH ? setMonthPeriod : null}
      label={key === PERIOD_WEEK ? TEXTS[0] : key === PERIOD_MONTH ? TEXTS[1] : null}
      active={period === key}
      className={isLayoutGroup ? '!text-sm' : null}
      isFirstInList={isLayoutGroup ? index === 0 : null}
    />
  ));

  if (isLayoutGroup) {
    return (
      <div className="flex items-center border border-gray-250 rounded-md divide-x">{buttons}</div>
    );
  }
  return <div className="flex items-center">{buttons}</div>;
};

TableHeaderPeriodControls.propTypes = {
  fromPanel: PropTypes.bool,
  layout: PropTypes.string,
};

export default TableHeaderPeriodControls;
