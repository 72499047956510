import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Alert from 'components/Alert';
import Modal from 'components/Modal';
import { useAuth } from 'context/auth.context';
import { changePassword } from 'context/profile.methods';
import ProfileEditModal from './components/EditModal';

const Input = ({ label, id, ...props }) => (
  <div className="flex w-full items-center mb-5">
    <label htmlFor={id} className="w-[40%] md:w-40 whitespace-nowrap mr-2">
      {label}
    </label>
    <input
      type="password"
      id={id}
      className="w-[60%] md:w-80 appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green"
      {...props}
    />
  </div>
);
Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

const Label = ({ children }) => <div className="text-gray-400 text-xs lg:text-sm">{children}</div>;
Label.propTypes = {
  children: PropTypes.string.isRequired,
};

const Item = ({ label, value, editable, mode, editableValues, onSuccess }) => (
  <div className="flex justify-between items-center pb-3 mb-3 border-b border-gray-400 lg:pb-6 lg:mb-6">
    <Label>{label}</Label>
    <div className="text-xs font-bold lg:text-sm flex gap-x-2 items-center">
      {value}{' '}
      {editable && <ProfileEditModal mode={mode} values={editableValues} onSuccess={onSuccess} />}
    </div>
  </div>
);
Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  editable: PropTypes.bool,
  mode: PropTypes.string,
  editableValues: PropTypes.object,
  onSuccess: PropTypes.func,
};

const Profile = () => {
  const { user } = useAuth();
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [alerts, setAlerts] = useState([]);
  const addAlertByKey = ({ key, message, type }) => {
    setAlerts((prev) => [...prev, { key, message, type }]);
  };

  const removeErrorByKey = (key) => {
    setAlerts((prev) => prev.filter((error) => error.key !== key));
  };

  let countErrors = 0;

  useEffect(() => {
    if (repeatPassword === newPassword) {
      setPasswordError(false);
    }
  }, [repeatPassword, newPassword]);

  const handleCloseModal = () => {
    setIsOpenChangePassword(false);
    setOldPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setPasswordError('');
  };

  const handleChangePassword = () => {
    const oldPasswordFormatted = oldPassword.trim();
    const newPasswordFormatted = newPassword.trim();
    const repeatPasswordFormatted = repeatPassword.trim();
    if (
      !oldPasswordFormatted.length ||
      !newPasswordFormatted.length ||
      !repeatPasswordFormatted.length ||
      newPasswordFormatted.length < 6
    ) {
      return;
    }
    if (newPasswordFormatted !== repeatPasswordFormatted) {
      setPasswordError('Пароли не совпадают');
      return;
    }

    changePassword(oldPasswordFormatted, newPasswordFormatted, repeatPasswordFormatted).then(
      ({ data }) => {
        const { status, success } = data;
        if (success) {
          addAlertByKey({
            key: `error-${++countErrors}`,
            message: 'Ваш пароль успешно изменен',
            type: 'success',
          });

          setIsOpenChangePassword(false);
          setOldPassword('');
          setNewPassword('');
          setRepeatPassword('');
        } else {
          addAlertByKey({
            key: `error-${++countErrors}`,
            message: status === 'Wrong current password' ? 'Неверный текущий пароль' : status,
            type: 'error',
          });
        }
      },
    );
  };

  const onSuccessName = () => {
    addAlertByKey({
      key: `success-name`,
      message: 'Имя успешно изменено',
      type: 'success',
    });
  };

  const onSuccessEmail = () => {
    addAlertByKey({
      key: `success-name`,
      message: 'Email успешно изменен',
      type: 'success',
    });
  };

  const disableSubmit =
    newPassword?.replace(/\s/g, '').length < 6 || newPassword !== repeatPassword;

  return (
    <div className="px-3 lg:px-8">
      <h1 className="text-xl font-bold mb-5 lg:mb-4 mt-2 lg:mt-7 lg:mb-12 lg:text-2xl lg:mt-14">
        Профиль
      </h1>
      <div className="flex flex-col lg:max-w-[320px]">
        <Item
          label="Представьтесь"
          value={user?.name}
          editable
          mode={'name'}
          editableValues={{ name: user?.name }}
          onSuccess={onSuccessName}
        />
        <Item
          label="Email"
          value={user?.email}
          editable
          mode={'email'}
          editableValues={{ email: user?.email }}
          onSuccess={onSuccessEmail}
        />
        <div className="flex justify-between items-center">
          <Label>Пароль</Label>
          <button
            className="text-green cursor-pointer text-xs lg:text-sm"
            onClick={() => setIsOpenChangePassword(true)}
          >
            Изменить пароль
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpenChangePassword}
        title="Изменить пароль"
        submitButtonText="Сохранить"
        submitModal={handleChangePassword}
        closeModal={handleCloseModal}
        width={'auto'}
        submitButtonDisabled={disableSubmit}
      >
        <Input
          label="Текущий пароль"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          id="oldPassword"
        />
        <Input
          label="Новый пароль"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          id="newPassword"
        />
        <div className="flex w-full items-center">
          <div className="w-[40%] md:w-40 whitespace-nowrap" />
          <ul className="text-gray-500 list-disc ml-5 mb-5">
            <li>не менее 6 символов</li>
          </ul>
        </div>
        <Input
          label="Повторить"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          id="repeatPassword"
        />
        <div className="flex w-full items-center">
          <div className="w-[40%] md:w-40 whitespace-nowrap" />
          <div className="text-red-600">{passwordError}</div>
        </div>
      </Modal>
      <Alert hide={removeErrorByKey} items={alerts} placement="bottom" />
    </div>
  );
};

export default Profile;
