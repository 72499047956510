import { PlusIcon } from 'assets/icons/PlusIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import WarehouseSelect from 'components/Filter/components';
import { ProductSearchModalButton } from 'components/ProductsSearch';
import TableHeaderDateControls from 'components/TableHeaderDateControls';
import TableMyProductsFilter from 'components/TableMyProductsFilter';
import PropTypes from 'prop-types';
import { useComparisonContext } from '../../../context/comparison.context';
import ProductListModalButton from '../ProductListModalButton';

const ComparisonLayout = ({ children }) => {
  const { resetComparison, hasProducts, updateProductsFromSearch } = useComparisonContext();
  // const listId = null;
  //LIST_TYPE.BY_PRODUCTS;
  return (
    <>
      <div className="min-h-screen flex flex-col">
        <div className="bg-white pt-2 lg:pt-3 pb-2 lg:pb-3">
          <div className="wrapper-wide flex justify-between items-center">
            <h1>Сравнение товаров</h1>
            <div className="flex items-center space-x-2 h-fit">
              <TableMyProductsFilter />
              <ProductListModalButton />
              <div className="w-[1px] h-6 bg-[#E7EDF0]" />
              <TableHeaderDateControls />
            </div>
          </div>
          {hasProducts && (
            <div className="pt-4 lg:pt-3">
              <div className="wrapper-wide flex gap-2 w-full">
                <div className="flex justify-between w-full">
                  <div className="flex items-start justify-start lg:justify-between space-x-2">
                    <WarehouseSelect />

                    <ProductSearchModalButton
                      button={{
                        icon: <PlusIcon className={'h-4 w-4'} />,
                        label: 'Добавить Товар',
                      }}
                      modal={{ onComplete: updateProductsFromSearch }}
                    />
                  </div>
                  <div>
                    <button className="text-green flex space-x-1 py-1" onClick={resetComparison}>
                      <TrashIcon className={'w-4 h-4'} /> <span>Сбросить</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  );
};

ComparisonLayout.propTypes = {
  children: PropTypes.node,
};
export default ComparisonLayout;
