import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useFetchFilteredData } from 'hooks/useFetchFilteredData';

const Season = () => {
  const { data, isLoading, isFetching } = useFetchFilteredData({
    type: TABLE_TYPE.SEASONALITY,
  });
  return (
    <Table
      data={data}
      type={TABLE_TYPE.SEASONALITY}
      hidePagination
      isLoading={isLoading || isFetching}
      noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
    />
  );
};

export default Season;
