import AuthorizationForm from 'components/AuthorizationForm';
import { useAuth } from 'context/auth.context';
import { useState } from 'react';
import { useSearchParams } from 'react-router';
import { sendToGA } from 'utils/gtag_ym';

const initialValues = {
  // password: '',
  // rePassword: '',
  phone: '+7',
  name: '',
  email: '',
  agree: false,
};

const SignUpStart = () => {
  const { checkPhoneNumber, setPhoneNumber, setStep, setSignUpFormData, signUpFormData } =
    useAuth();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [regInited, setRegInited] = useState(false);
  const isDemo = searchParams.get('demo-message');

  const handleSubmit = (values) => {
    sendToGA('mainEvent', {
      eventCategory: 'Registration',
      eventAction: 'Click',
      eventLabel: 'LoginPassword',
      user_id: 'none',
    });

    let resultNumber = values.phone?.replaceAll('-', '');
    resultNumber = resultNumber.replaceAll(')', '');
    resultNumber = resultNumber.replaceAll('(', '');
    return checkPhoneNumber({
      phone_number: resultNumber,
      captcha: values.captcha,
      email: values.email,
    })
      .then((data) => {
        if (data.error) {
          const firstErrorKey = data.error?.[Object.keys(data.error)?.[0]]?.[0];
          setError(firstErrorKey || data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
        } else {
          setPhoneNumber(resultNumber);
          setSignUpFormData(values);
          setStep(1);
          setError(null);
        }
        return data;
      })
      .catch((error) => {
        const response = error.response || {};
        const data = response.data || {};
        const message = data.message ?? 'Ошибка';
        setError(message);
        setSignUpFormData(null);

        sendToGA('mainEvent', {
          eventCategory: 'Message - Front',
          eventAction: 'Message',
          eventLabel: 'RegistrationError "' + message + '"',
          user_id: 'none',
        });
      });
  };

  const registerGA4 = () => {
    if (!regInited) {
      sendToGA('mainEvent', {
        eventCategory: 'Registration',
        eventAction: 'Start',
        eventLabel: 'LoginPassword',
        user_id: 'none',
      });
      setRegInited(true);
    }
  };

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'name',
          label: 'Имя',
          type: 'text',
          onClick: registerGA4,
          rules: [
            { required: true },
            {
              rule: 'length',
              max: 64,
              message: 'Имя не должно превышать 64 символа',
            },
            {
              rule: 'regex',
              regex: /^[\p{L}\s\-.]+$/u,
              message: 'Имя может содержать буквы, пробел, тире и точку',
            },
          ],
        },
        {
          name: 'phone',
          label: 'Номер телефона',
          type: 'tel',
          onClick: registerGA4,
          rules: [{ rule: 'phone', message: 'Номер телефона', required: true }],
        },
        {
          name: 'email',
          label: 'E-mail',
          type: 'text',
          onClick: registerGA4,
          rules: [{ rule: 'email', message: 'Введите валидный email', required: true }],
        },
        {
          name: 'agree',
          onClick: registerGA4,
          label: (
            <a
              href="https://www.wecheck.ru/agreement"
              target="_blank"
              rel="noreferrer"
              className="text-green underline"
            >
              Принимаю условия публичной оферты
            </a>
          ),
          type: 'checkbox',
        },
      ]}
      initialValues={signUpFormData || initialValues}
      onSubmit={handleSubmit}
      submitLabel="Регистрация"
      messageText={
        isDemo ? (
          <div className="-mt-4 mb-8 text-gray-500 text-left md:flex md:-mx-36">
            Для доступа к демоданным, вам необходимо зарегистрироваться
          </div>
        ) : undefined
      }
      superError={error}
      useCaptcha
    />
  );
};

export default SignUpStart;
