import { generateGridColumns } from 'helpers/generateGridColumns';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useFilterReducer } from '../../context/filter/filter.context.reducer';
import { useTable } from '../../context/table.context';
import { useTableTemplates } from '../../context/table.templates.context';
import baseClient from '../../services/baseClient';
import { generateTemplateName } from '../../utils/templates';
import ButtonPrimary from '../ButtonPrimary';
import { TableTemplate } from './TableTemplate';

const TableSidebarTemplatesPanel = () => {
  const [templates, setTemplates] = useState([]);
  const {
    fullFilterState,
    filter: { period },
  } = useFilterReducer();
  const { type, gridOptions, initialData } = useTable();
  const { setActiveTemplate } = useTableTemplates();

  useEffect(() => {
    const list = document.getElementsByClassName('ag-tool-panel-wrapper');
    const sidebar = list[list.length - 1];
    if (sidebar) {
      sidebar.style.width = '368px';
    }
  }, []);

  const loadTemplates = useCallback(async () => {
    const resultUrl = window.location.pathname.split('/').slice(1).join('-');
    const resp = await baseClient.loadTemplates({ url: resultUrl });
    setTemplates(resp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (type) {
      loadTemplates();
    }
  }, [type, loadTemplates]);

  const generateUiIdForTemplate = () => {
    for (let i = 0; ; i++) {
      let newId = 'Template_' + i;
      if (templates.findIndex(({ id }) => id === newId) === -1) {
        return newId;
      }
    }
  };
  const handleCreateTemplate = () => {
    // const { columnDefs } = props.columnApi.columnModel;
    const columnDefs = gridOptions.api.getColumnDefs();
    const newTemplId = generateUiIdForTemplate();
    const resultUrl = window.location.pathname.split('/').slice(1).join('-');
    let { filter } = fullFilterState || {};
    // filter.date = undefined;
    // let filterWithoutDates = { ...filter };
    // filterWithoutDates.date = undefined;
    let filterWithoutDates = { ...fullFilterState, filter: { ...filter, date: undefined } };

    setTemplates([
      {
        id: newTemplId,
        isNew: true,
        isEdit: true,
        name: generateTemplateName('Новый шаблон'),
        colDefs: JSON.stringify(columnDefs),
        filter: JSON.stringify(filterWithoutDates),
        url: resultUrl,
      },
      ...templates,
    ]);
    setActiveTemplate(resultUrl, newTemplId);
  };

  const handleSetDefault = () => {
    setTimeout(() => {
      gridOptions.columnApi.resetColumnState();
      // this is needed for dynamic columns
      const defaultDefs = generateGridColumns({
        type,
        data: initialData,
        period,
      });

      gridOptions.api.setColumnDefs(defaultDefs);
    }, 0);
  };

  return (
    <div className="py-4 px-5">
      <span className="block text-black font-medium text-lg">Шаблоны настроек</span>
      <span className="block text-[#6E6E6E] my-3 text-sm">
        Настройте порядок и активность колонок,
        <br />а так же фильтры и сортировки.
        <br />
        Сохраните шаблон и используйте его
        <br />в будущем для быстрой настройки таблицы.
      </span>
      <div className="flex justify-between w-full">
        <ButtonPrimary
          onClick={handleCreateTemplate}
          label={'Сохранить настройки таблицы'}
          className="py-2 text-[14px] leading-[16px] font-normal px-3"
          disabled={templates.length >= 20}
        />
        <button
          className="px-3 py-2 border border-gray-250 rounded inline-flex items-center hover:text-green hover:border-green"
          onClick={handleSetDefault}
        >
          <span>Сбросить</span>
        </button>
      </div>
      {!!templates.length && (
        <div className="mt-3 border-t border-[rgb(33,150,83,0.12)] pt-3">
          <span className="block text-[#6E6E6E] text-sm mb-3">Сохраненные шаблоны</span>
          <div className="space-y-2">
            {templates.map((templ, index) => (
              <TableTemplate
                templ={templ}
                key={templ.id}
                index={index}
                templates={templates}
                setTemplates={setTemplates}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

TableSidebarTemplatesPanel.propTypes = {
  reactContainer: PropTypes.any,
  api: PropTypes.any,
  columnApi: PropTypes.any,
};

export { TableSidebarTemplatesPanel };
