import RefreshIcon from 'assets/icons/RefreshIcon';
import cn from 'classnames';
import { TABLE_TYPE } from 'constants';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ButtonPrimary from '../ButtonPrimary';
import Table from '../Table';
import CompareDiagram from './CompareDiagram';
import { TAB_ONLY_A, TAB_ONLY_B, TABS_GROUP } from './constants';

const CompareResult = ({ data, handleNewCompare, isLoading }) => {
  const { products_group_a = [], products_group_b = [] } = data;
  const [activeTab, setActiveTab] = useState(TABS_GROUP.GROUP_A.value);
  return (
    <>
      <div className="bg-[#F0F0F0] py-4 px-8 border-t border-[#E8E8E8]">
        <div className="flex mb-4">
          <ButtonPrimary
            onClick={handleNewCompare}
            label={
              <div className="flex space-x-2 items-center py-[3px]">
                <RefreshIcon width={16} height={16} />
                <span className="block">Новый запрос</span>
              </div>
            }
            className="text-base group !pl-[14px]"
          />
        </div>
        <div className="flex space-x-2">
          <div className="w-[468px] no-padding">
            <Table
              data={products_group_a}
              type={TABLE_TYPE.SEO_COMPARE_A_PRODUCTS}
              isLoading={isLoading}
              noRowsText={`По вашему запросу нет данных.`}
              showSidebar={false}
              useFooter={false}
            />
          </div>
          <div className="w-[468px] no-padding">
            <Table
              data={products_group_b}
              type={TABLE_TYPE.SEO_COMPARE_B_PRODUCTS}
              isLoading={isLoading}
              noRowsText={`По вашему запросу нет данных.`}
              showSidebar={false}
              useFooter={false}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[45%]">
          <div className="flex py-4 px-8 space-x-3 border-r border-[#E8E8E8]">
            <div className="flex">
              {Object.values(TABS_GROUP).map((el, index) => {
                const isActive = activeTab === el.value;
                return (
                  <button
                    key={el.value}
                    onClick={() => setActiveTab(el.value)}
                    className={cn(
                      'block whitespace-nowrap z-0 relative border-t border-b border-r border-gray-250 px-3 pb-1.5 pt-2.5 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green',
                      {
                        '!text-green bg-[#F1FCF6]': isActive,
                        'rounded-l border-l': index === 0,
                        'rounded-r': index === Object.values(TABS_GROUP).length - 1,
                      },
                    )}
                  >
                    {el.label}
                  </button>
                );
              })}
            </div>
            <button
              onClick={() => setActiveTab(TAB_ONLY_A.value)}
              className={cn(
                'rounded block whitespace-nowrap z-0 relative border border-gray-250 px-3 pb-1.5 pt-2.5 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green',
                {
                  '!text-green bg-[#F1FCF6]': TAB_ONLY_A.value === activeTab,
                },
              )}
            >
              {TAB_ONLY_A.label}
            </button>
            <button
              onClick={() => setActiveTab(TAB_ONLY_B.value)}
              className={cn(
                'rounded block whitespace-nowrap z-0 relative border border-gray-250 px-3 pb-1.5 pt-2.5 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green',
                {
                  '!text-green bg-[#F1FCF6]': TAB_ONLY_B.value === activeTab,
                },
              )}
            >
              {TAB_ONLY_B.label}
            </button>
          </div>
          <div className="no-padding">
            <Table
              data={data[activeTab]?.phrases || []}
              type={TABLE_TYPE.SEO_COMPARE_RESULTS}
              isLoading={isLoading}
              noRowsText={`По вашему запросу нет данных.`}
              paginationOnBack
              showSidebar={false}
              footerExtra={
                <span className="text-[#858585]">{`Всего ${data[activeTab]?.phrases_qty} фраз`}</span>
              }
            />
          </div>
        </div>
        <CompareDiagram
          groupB={data['group_a']?.phrases_qty}
          groupA={data['group_b']?.phrases_qty}
          cross={data['cross']?.phrases_qty}
        />
      </div>
    </>
  );
};

CompareResult.propTypes = {
  data: PropTypes.any,
  handleNewCompare: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CompareResult;
