import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { tariffsEnum } from 'constants/tariffs';
import { usePayment } from 'context/payment.context';
import { useTariffs } from 'context/tariffs.context';
import { TariffColumn } from './';

export const TariffColumnList = ({ modalView }) => {
  const { tariffsData } = useTariffs();
  const { onSelectTariff } = usePayment();

  return (
    <div className="max-w-7xl mx-auto lg:mt-8 lg:flex justify-center py-10 -mb-4 gap-5">
      {Object.keys(tariffsData).map((key) => (
        <Fragment key={key}>
          <TariffColumn
            item={tariffsData[key]}
            onSubmit={() => onSelectTariff(key)}
            modalView={modalView}
            highlight={+key === +tariffsEnum.PRO}
          />
        </Fragment>
      ))}
    </div>
  );
};

TariffColumnList.propTypes = {
  modalView: PropTypes.bool,
};
