import FilterModalOuter from 'components/FilterModal/FilterModalOuter';
import { FilterModalProvider } from 'context/filter.modal.context';
import { useNavigate } from 'react-router';

const { default: Modal } = require('components/Modal');
const { PAGE_MAIN } = require('constants');
const { useState } = require('react');

const DynamicNoFiltersModal = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  if (showModal) {
    return (
      <FilterModalProvider>
        <FilterModalOuter opened onCancel={() => setShowModal(false)} />
      </FilterModalProvider>
    );
  }
  return (
    <Modal
      width={450}
      title={'Для просмотра "Тренды" добавьте фильтры'}
      isOpen
      disableCloseOnClickOutside
      closeModal={() => navigate(PAGE_MAIN)}
      submitModal={() => setShowModal(true)}
      cancelButtonText={'Вернуться на главную'}
      submitButtonText={'Добавить фильтры'}
      disableCloseButton
    >
      Чтобы увидеть аналитику по трендам, добавьте фильтр по &quot;Категории&quot;,
      &quot;Продавцу&quot; или &quot;Бренду&quot; в таблице
    </Modal>
  );
};

export default DynamicNoFiltersModal;
