import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import LoadingSpin from 'components/LoadingSpin';
import { useUI } from 'context/ui.context';
import { useFetchCheckCpm } from 'hooks/useFetchCheckCpm';
import AdvertisingWBInfo from 'pages/AdvertisingRates/AdvertisingWBInfo';
import { MODAL_MODES } from './constants';
import ModalContent from './ModalContent/ModalContent';

const AdvertisingRatesHeader = ({ searchValue, setSearchValue, showMode, setShowMode }) => {
  const { isMobile } = useUI();
  const [inputValue, setInputValue] = useState('');
  const [isSearchMode, setSearchMode] = useState(false);
  const [mode, setMode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearchChange = (e) => {
    if (isSearchMode) {
      setSearchMode(false);
    }
    setInputValue(e.target.value);
  };
  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  const { data: allData = {}, isFetched, isLoading, isFetching } = useFetchCheckCpm(searchValue);

  const { success = true, data = {} } = allData;
  const {
    items: searchData = [],
    blocks_per_page = [],
    prioritySubjects = [],
    total,
    withadv,
  } = data || {};

  const handleLoad = () => {
    setSearchValue(inputValue);
    setSearchMode(true);
  };
  useEffect(() => {
    if (isFetched) {
      setSearchMode(true);
    }
  }, [isFetched]);

  const openSignIn = () => {
    setMode(MODAL_MODES.SIGN_IN);
    setIsModalOpen(true);
  };
  const openRegistration = () => {
    setMode(MODAL_MODES.REGISTRATION);
    setIsModalOpen(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLoad();
    }
  };

  let emptyMessage =
    'Не смогли получить данные по вашему запросу. Попробуйте скорректировать запрос';
  if (withadv === 0) {
    emptyMessage =
      'По вашему запросу не найдено товаров с рекламой. Попробуйте скорректировать запрос';
  }
  if (total === 0) {
    emptyMessage = 'По вашему запросу не найдено товаров. Попробуйте скорректировать запрос';
  }
  return (
    <div className="flex justify-center">
      <div className="mt-10 w-full lg:px-0 text-center sm:px-2 max-w-[1200px]">
        <span className="font-bold text-2xl text-gray-700">
          Сервис проверки рекламных ставок Wildberries
        </span>
        <div className={`py-4 border-b border-gray-250 block lg:flex`}>
          <input
            value={inputValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder="Введите поисковую фразу"
            disabled={!success}
            className={cn(
              `grow transition-colors border border-gray-250 rounded mr-3 placeholder:text-gray-400 px-5 pt-3 pb-2.5 placeholder:font-400 focus:outline-none focus:border focus:border-green`,
              {
                'bg-gray-100': !success,
              },
            )}
            style={{ width: isMobile ? '100%' : 530 }}
          />
          <button
            className={cn(`text-white pt-3 pb-2.5 px-5 rounded w-full mt-3 lg:w-auto lg:mt-0`, {
              'bg-gray-250': !success,
              'bg-green': success,
            })}
            onClick={handleLoad}
            disabled={!success}
          >
            Проверить
          </button>
        </div>
        {isLoading || isFetching ? (
          <LoadingSpin classNames="mt-2" />
        ) : (
          <>
            <span className="pt-4 text-gray-400 font-normal text-sm block">
              {!success ? (
                <span>
                  Без регистрации доступно 5 запросов в день.
                  <br />
                  <button className="border-none bg-none text-green" onClick={openRegistration}>
                    Зарегистрируйтесь
                  </button>
                  {' или '}
                  <button className="border-none bg-none text-green" onClick={openSignIn}>
                    Войдите
                  </button>
                  , чтобы бесплатно проверять рекламные ставки без ограничений.
                </span>
              ) : isSearchMode ? (
                searchData.length !== 0 ? (
                  <AdvertisingWBInfo
                    blocks_per_page={blocks_per_page}
                    setShowMode={setShowMode}
                    showMode={showMode}
                    count={searchData.length}
                    prioritySubjects={prioritySubjects}
                  />
                ) : (
                  emptyMessage
                )
              ) : (
                `Чтобы проверить размер ставки и позицию товара, введите поисковый запрос и нажмите проверить`
              )}
            </span>
            <ModalContent
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              mode={mode}
              setMode={setMode}
            />
          </>
        )}
      </div>
    </div>
  );
};
AdvertisingRatesHeader.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  showMode: PropTypes.string,
  setShowMode: PropTypes.func,
};

export default AdvertisingRatesHeader;
