import { WarehouseSelect } from 'components/Filter/components/WarehouseSelect';
import { ByPhraseContent } from 'components/SeoByPhrase';
import TableHeaderDateControls from 'components/TableHeaderDateControls';

const ByPhrase = () => {
  return (
    <div className="pt-4">
      <div className="px-8 flex justify-between">
        <div>
          <h1>Проверка товаров по поисковой фразе</h1>
          <div className="text-gray-400 mt-1 mb-4">
            <span className="block">Инструмент показывает динамику изменения позиций.</span>
            <span className="block">
              Отслеживайте, как меняются позиции товаров, по интересующей вас поисковой фразе
            </span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <WarehouseSelect />
            <div className="py-1 mx-2">
              <div className="w-px h-6 bg-[#E7EDF0]" />
            </div>
            <TableHeaderDateControls usePeriods={false} />
          </div>
        </div>
      </div>
      <ByPhraseContent />
    </div>
  );
};

export default ByPhrase;
