import Dropdown from 'components/Dropdown';
import { F } from 'constants/filter';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import {
  WAREHOUSE_FBO_OZON,
  WAREHOUSE_FBO_WB,
  WAREHOUSE_FBS_OZON,
  WAREHOUSE_FBS_WB,
} from 'context/filter/filter.context.reducer.methods';
import { useUI } from 'context/ui.context';
import { PlatformMobileButton } from './';
// import { useEffect } from 'react';

export const optionsWarehouseWb = [
  { value: 'all', label: 'Все' },
  { value: WAREHOUSE_FBO_WB, label: 'FBO' },
  { value: WAREHOUSE_FBS_WB, label: 'FBS' },
];

export const optionsWarehouseOzon = [
  { value: 'all', label: 'Все' },
  { value: WAREHOUSE_FBO_OZON, label: 'FBO' },
  { value: WAREHOUSE_FBS_OZON, label: 'FBS' },
];

export const WarehouseSelect = () => {
  const { isOzonPlatform, filter, /* resetFilter,*/ setWarehouse } = useFilterReducer();
  const selectedWarehouse = filter?.[F.WAREHOUSE][0];
  const { isMobile, setActiveModal } = useUI();

  const optionsWarehouse = isOzonPlatform ? optionsWarehouseOzon : optionsWarehouseWb;
  const selectedWarehousesLabel = optionsWarehouse
    .filter((item) => item.value === selectedWarehouse)
    .map((item) => item.label)
    .join(`, `);

  const isWarehouseSelected = !selectedWarehousesLabel
    ? `${isMobile ? 'Все' : 'Склад: Все'}`
    : `${isMobile ? '' : 'Склады: '}${selectedWarehousesLabel}`;

  const selected =
    optionsWarehouse?.filter((item) => item?.value === selectedWarehouse)?.[0]?.value || '';

  const OnMobileClick = () => {
    setActiveModal('warehouses');
  };

  const onChange = (value) => {
    setWarehouse(value);
  };

  if (isMobile) {
    return (
      <PlatformMobileButton
        bg="green"
        type="warehouses"
        label={isWarehouseSelected}
        onClick={OnMobileClick}
      />
    );
  }

  return (
    <Dropdown
      label={isWarehouseSelected}
      bgColor="green"
      color="green"
      options={optionsWarehouse}
      onChange={onChange}
      selected={selected}
      buttonClassnames="border-solid border px-[11px] py-[5px]"
      isOutlined
    />
  );
};
