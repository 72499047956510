import CrossIcon from 'assets/icons/CrossIcon';
import Modal from 'components/Modal';
import Tariffs from 'components/Tariffs';
import { usePayment } from 'context/payment.context';
import { useTariffs } from 'context/tariffs.context';
import { useUI } from 'context/ui.context';

const TariffsModal = () => {
  const { isMobile } = useUI();

  const { isOpenTariffsModal, activeTariff } = useTariffs();
  const { onSelectTariff } = usePayment();

  const handleClose = () => {
    onSelectTariff(activeTariff);
  };

  if (!isOpenTariffsModal) return <></>;

  if (isMobile) {
    return (
      <div className="top-0 left-0 fixed min-w-[100vw] min-h-[100vh] bg-white z-[10100]">
        <div className="flex absolute w-full h-full left-0 top-0 grow overflow-y-auto overflow-x-hidden flex-col">
          <div className="p-4 flex justify-between items-center">
            <div className="font-bold text-base">Выберите тариф</div>
            <CrossIcon onClick={handleClose} className="h-5 w-5 text-green fill-green" />
          </div>
          <Tariffs modalView onSelectTariff={onSelectTariff} />
        </div>
      </div>
    );
  }
  return (
    <Modal
      isOpen={isOpenTariffsModal}
      closeModal={handleClose}
      disableCloseOnClickOutside={true}
      footer={false}
      title={<>Выберите тариф</>}
      width={window.screen.width - (window.screen.width / 100) * 10}
    >
      <Tariffs modalView onSelectTariff={onSelectTariff} />
    </Modal>
  );
};

export default TariffsModal;
